import { useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  ChainChevron,
  ChainDropDown,
  ChainIcon,
  ChainIconInner,
  ChainItem,
  ChainName,
  ChainToggleBox,
  ChainToggleBtn,
  ChainToggleItem,
} from './styles'
import { Text } from 'rebass'
import { IconWrapper, useMediaWith, CloseIconCircle } from 'theme'
import { ChainId, NATIVE_CURRENCY } from 'sdk'
import { chains } from './constants'
import { Box, setupNetwork } from 'shared'
import Modal from 'components/Modal'
import Column, { AutoColumn } from 'components/Column'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { AcceptTwoIcon } from 'icons/AcceptTwoIcon'
import Row from 'components/Row'
import { listedTokensList } from 'constants/aggregatorTokensList'

type Props = {
  selectedChainId: ChainId
  setSelectedChainId: (value: ChainId) => void
}

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`

export const PaddedColumn = styled(AutoColumn)`
  padding: 10px;
  background-color: ${({ theme }) => theme.componentBg4};
  border-bottom: 0.5px solid #ffffff1a;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: end;
`

export const ContentColumn = styled(AutoColumn)``

export const ChainToggle = ({ selectedChainId, setSelectedChainId }: Props) => {
  const { upToSmall } = useMediaWith()
  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()
  const history = useHistory()
  const { pathname } = useLocation()
  useOnClickOutside(menuRef, menuOpen ? () => setMenuOpen(!menuOpen) : undefined)
  const sortedChains = Object.values(chains).sort((itemA, itemB) => itemA?.name.localeCompare(itemB?.name))
  const mainnetChains = [
    sortedChains.find((chain) => chain.chainId === ChainId.LIF3CHAIN),
    ...sortedChains.filter((chain) => !chain.isTestnet && chain.chainId !== ChainId.LIF3CHAIN),
  ]
  const testnetChains = sortedChains.filter((chain) => chain.isTestnet)

  const selectChain = async (chainId: ChainId) => {
    try {
      await setupNetwork(chainId)
      setSelectedChainId(chainId)
      setMenuOpen(false)

      const search = new URLSearchParams(location.search)
      search.set('inputCurrency', String(NATIVE_CURRENCY[chainId].symbol))
      search.set('outputCurrency', String(listedTokensList[chainId].find((el) => el.symbol === 'LIF3')?.address))
      if (pathname === '/pool/add') {
        history.push({ search: search.toString() })
      } else if (pathname.includes('/tokens')) {
        history.replace({ search: search.toString() })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onDismiss = () => {
    setMenuOpen(false)
  }

  const renderChainRows = (chains) =>
    chains.map((chain) => (
      <Row key={chain.name}>
        <ChainItem
          {...(chain.url
            ? { href: chain.url, target: chain.isInnerLink ? '_blank' : '' }
            : { onClick: () => selectChain(chain.chainId) })}
          bg={selectedChainId === chain.chainId ? chain.color : ''}
        >
          <ChainIcon src={chain.logo} title={chain.name} />
          <Text fontSize="15px" lineHeight="19px">
            {chain.name}
          </Text>
        </ChainItem>
      </Row>
    ))

  const renderChainItems = (chains) =>
    chains.map((chain) => (
      <ChainToggleItem
        {...(chain.url
          ? { href: chain.url, target: chain.isInnerLink ? '_blank' : '', as: 'a' }
          : { onClick: () => selectChain(chain.chainId) })}
        bg={chain.color}
        key={chain.chainId}
        isActive={selectedChainId === chain.chainId}
      >
        <Box display="flex" justifyContent="center" alignItems="center" width="18px" height="18px">
          <ChainIconInner src={chain.logo} title={chain.name} />
        </Box>

        <Text fontSize="14px" lineHeight="18px">
          {chain.name}
        </Text>
        {chain.new && <ChainChevron>New</ChainChevron>}
        {selectedChainId === chain.chainId && (
          <IconWrapper size="14px" marginLeft="auto">
            <AcceptTwoIcon />
          </IconWrapper>
        )}
      </ChainToggleItem>
    ))

  return (
    <ChainToggleBox ref={menuRef}>
      <ChainToggleBtn bg={chains[selectedChainId]?.color} onClick={() => setMenuOpen(!menuOpen)}>
        <ChainIcon src={chains[selectedChainId]?.logo} />
        <ChainName fontSize={13}>{chains[selectedChainId]?.name}</ChainName>
      </ChainToggleBtn>

      {upToSmall ? (
        <Modal
          isOpen={menuOpen}
          onRequestClose={onDismiss}
          maxWidth={'281px'}
          background={theme.componentBg4}
          borderRadius="5px"
        >
          <ContentWrapper>
            <PaddedColumn gap="10px">
              <Box />
              <Text fontWeight={400} fontSize={13} color="#FFFFFF80" alignSelf="center">
                Network selection
              </Text>
              <CloseIconCircle onClick={onDismiss} />
            </PaddedColumn>
            <ContentColumn>
              {renderChainRows(mainnetChains)}
              <Box paddingY="8px">{renderChainRows(testnetChains)}</Box>
            </ContentColumn>
          </ContentWrapper>
        </Modal>
      ) : (
        <Box display="flex" flexDirection="column">
          {menuOpen && (
            <ChainDropDown>
              <Text fontSize="13px" padding="7px 10px" mb="3px">
                Network selection
              </Text>
              {renderChainItems(mainnetChains)}
              <Text fontSize="13px" padding="7px 10px" mb="3px">
                Testnet
              </Text>
              {renderChainItems(testnetChains)}
            </ChainDropDown>
          )}
        </Box>
      )}
    </ChainToggleBox>
  )
}
