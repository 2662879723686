import styled, { keyframes } from 'styled-components'
import { Text } from 'rebass'

export const ChainToggleBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const ChainToggleBtn = styled.a<{ bg?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  gap: 8px;
  padding: 5px 25px;

  background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 0;
    width: 35px;
  `}
`

export const ChainToggleItem = styled.button<{ bg?: string; isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 9px 10px;
  gap: 8px;
  background: transparent;
  box-shadow: none;
  transition: opacity 0.3s, background-color 0.3s;
  background: ${({ bg, isActive }) => (isActive ? bg : 'transparent')};
  color: ${({ theme }) => theme.primaryText1};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: center;
    background: ${({ bg, theme }: any) => (bg ? bg : theme.appBg)};
  `}
`

export const ChainName = styled(Text)`
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'IBM Plex Sans';
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    display: none;
  `}
`

export const ChainIcon = styled.img`
  max-width: 20px;
  max-height: 20px;
  min-width: 10px;
`

export const ChainIconInner = styled(ChainIcon)`
  min-width: 16px;
  width: 100%;
  height: 100%;
`

export const ChainDropDown = styled.span`
  width: 125px;
  min-width: 180px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  z-index: 100;
  color: ${({ theme }) => theme.accentText};
  background: ${({ theme }) => theme.componentBg4};
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 20px 40px, rgba(0, 0, 0, 0.094) 0px 10.125px 17.4375px,
    rgba(0, 0, 0, 0.07) 0px 4px 6.5px, rgba(0, 0, 0, 0.047) 0px 0.875px 2.3125px;

  & > div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: auto;
    padding: 6px 0;
    left: -5px;
    align-items: center;
    width: calc(100% + 10px);
  `}
`

export const ChainBtn = styled.a<{ bg?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  min-width: 152px;
  min-height: 44px;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  font: inherit;
  color: ${({ theme }) => theme.primaryText1};
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    min-width: auto;
  `}
`

export const ChainItem = styled(ChainBtn)<{ bg?: string }>`
  padding: 15px 20px;
  background: transparent;
  box-shadow: none;
  min-width: auto;
  justify-content: flex-start;
  transition: opacity 0.3s, background-color 0.3s;
  text-decoration: none;
  min-height: auto;

  &:hover {
    background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    background: ${({ bg }: any) => (bg ? bg : 'transparent')};
  `}
`

const anime = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const ChainChevron = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 2px;

  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  background: ${({ theme }) => theme.buttonGradientBg};
  transition: all 0.3s;
  background-size: 600%;
  animation: ${anime} 12s linear infinite;

  &::after {
    position: absolute;
    margin-top: -70px;
    z-index: -1;
    filter: blur(30px);
    background: ${({ theme }) => theme.buttonGradientBg};
    opacity: 0.8;
    content: '';
    background-size: 600%;
    animation: ${anime} 12s linear infinite;
  }
`

export const TestnetTitle = styled.span`
  display: flex;
  align-items: center;
`

export const TestnetLine = styled.div`
  background-color: #ffffff80;
  width: 100%;
  height: 1px;
  margin: 0 10px 0 5px;
`

export const TestnetText = styled.p`
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  padding: 7px 10px;
  color: ${({ theme }) => theme.white};
  margin: 0;
`
