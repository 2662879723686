import axios from 'axios'
import { parseUnits } from '@ethersproject/units'
import qs from 'qs'
import { Currency, CurrencyAmount, JSBI, Token, TokenAmount, Trade, NATIVE_CURRENCY, WNATIVE } from 'sdk'
import { ParsedQs } from 'qs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ERC20ApproveAbi } from 'shared/abi/ERC20ApproveAbi'
import { useActiveWeb3React, useChainId } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { useTradeExactIn, useTradeExactOut } from '../../hooks/Trades'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { getSigner, isAddress } from '../../utils'
import { AppDispatch, AppState } from '../index'
import { useLPorCurrencyBalanceQuery } from '../wallet/hooks'
import { replaceSwapState, selectCurrency, setRecipient, switchCurrencies, typeInput, typeInputLimit } from './actions'
import { SwapState } from './reducer'
import { useUserSlippageTolerance } from '../user/hooks'
import { computeSlippageAdjustedAmounts } from '../../utils/prices'
import { PROJECT_TOKEN_ADDRESS } from 'constants/contracts'
import { CurrencyDirection } from 'enums/common'
import { useHistory, useLocation } from 'react-router'
import { wrappedCurrency } from 'shared'
import { UseQueryResult } from 'react-query'
import { useUpdateEffect } from 'react-use'
import { SUPPORTED_NETWORKS_IDS } from 'connectors'
import { useTranslation } from 'react-i18next'
import { useGetFirebirdQuote } from 'hooks/queries/useGetFirebirdQuote'
import { ethers } from 'ethers'
import { useTransactionAdder } from 'state/transactions/hooks'
import { fromWei } from 'pages/Farms/utils/formatNumber'
import { listedTokensList } from 'constants/aggregatorTokensList'
import { useWeb3React } from '@web3-react/core'
// import { ROUTER_ADDRESSES_1INCH } from 'constants/v3/addresses'
// import isZero from 'utils/isZero'
// import { toHex } from '@uniswap/v3-sdk'
// import { buildTxForSwap1Inch } from 'utils/1inch/api'
// import { useAddPopup } from 'state/application/hooks'
// import { generate1InchSwapParmas } from 'utils/generate1inchSwapParams'
import { ChainId, odosBaseUrl, polygonReferralCode } from 'sdk/constants'
import { formatByDecimals } from 'utils/formatters'

export function useCurrencyToRouteParams() {
  const chainId = useChainId()
  const { inputCurrency, outputCurrency, wrappedInputCurrency, wrappedOutputCurrency } = useSelectedCurrencies()
  const history = useHistory()
  const location = useLocation()
  const queryObject = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  useUpdateEffect(() => {
    if (inputCurrency && outputCurrency) {
      const getInputCurrency = () => {
        const isInputETH = inputCurrency.symbol === NATIVE_CURRENCY[chainId].symbol
        const isCard = inputCurrency.symbol === Currency.USD_CARD.symbol

        if (isCard) {
          return Currency.USD_CARD.symbol
        }

        return isInputETH ? NATIVE_CURRENCY[chainId].symbol : wrappedInputCurrency?.address
      }

      const getOutputCurrency = () => {
        const isOutputETH = outputCurrency.symbol === NATIVE_CURRENCY[chainId].symbol
        const isCard = outputCurrency.symbol === Currency.USD_CARD.symbol

        if (isCard) {
          return NATIVE_CURRENCY[chainId].symbol
        }

        return isOutputETH ? NATIVE_CURRENCY[chainId].symbol : wrappedOutputCurrency?.address
      }

      history.push({
        search: qs.stringify({
          ...queryObject,
          inputCurrency: getInputCurrency(),
          outputCurrency: getOutputCurrency(),
        }),
      })
    }
  }, [inputCurrency?.symbol, outputCurrency?.symbol, chainId])
}

export function useSwapState(): AppState['swap'] {
  return useSelector<AppState, AppState['swap']>((state) => state.swap)
}

export const useSelectedCurrencies = () => {
  const {
    [CurrencyDirection.INPUT]: { currencyId: inputCurrencyId },
    [CurrencyDirection.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()

  const chainId = useChainId()

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const wrappedInputCurrency = wrappedCurrency(inputCurrency ?? undefined, chainId)
  const wrappedOutputCurrency = wrappedCurrency(outputCurrency ?? undefined, chainId)
  return { inputCurrency, outputCurrency, wrappedInputCurrency, wrappedOutputCurrency }
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: CurrencyDirection, currency: Currency) => void
  onSwitchTokens: () => void
  onUserInput: (field: CurrencyDirection, typedValue: string) => void
  onChangeRecipient: (recipient: string | null) => void
  onUserLimitInput: (typedValue: string) => void
} {
  const chainId = useChainId()
  const dispatch = useDispatch<AppDispatch>()

  const onCurrencySelection = useCallback(
    async (field: CurrencyDirection, currency: Currency) => {
      const getCurrencyId = () => {
        if (currency === Currency.USD_CARD) {
          return 'USD'
        }

        return currency instanceof Token
          ? currency.address
          : currency === NATIVE_CURRENCY[chainId]
          ? NATIVE_CURRENCY[chainId].symbol
          : ''
      }

      const currencyId = getCurrencyId()
      if (currency === Currency.USD_CARD) {
        dispatch(
          selectCurrency({
            field: CurrencyDirection.OUTPUT,
            currencyId: NATIVE_CURRENCY[chainId].symbol,
          })
        )
      }

      dispatch(
        selectCurrency({
          field,
          currencyId,
        })
      )
    },
    [dispatch, chainId]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: CurrencyDirection, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }))
    },
    [dispatch]
  )

  const onUserLimitInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInputLimit({ typedValue }))
    },
    [dispatch]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
    onUserLimitInput,
  }
}

// try to parse a user entered amount for a given token
export function tryParseAmount(
  value: string | undefined,
  currency: Currency | undefined,
  chainId: number
): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined
  }

  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed), chainId)
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

const BAD_RECIPIENT_ADDRESSES: string[] = [
  '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f', // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a', // v2 router 01
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', // v2 router 02
]

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */
function involvesAddress(trade: Trade, checksummedAddress: string): boolean {
  return (
    trade.route.path.some((token) => token.address === checksummedAddress) ||
    trade.route.pairs.some((pair) => pair.liquidityToken.address === checksummedAddress)
  )
}

export function useDerivedSwapInfo(): {
  currencies: { [field in CurrencyDirection]?: Currency }
  currencyBalances: { [field in CurrencyDirection]?: CurrencyAmount | null }
  parsedAmount: CurrencyAmount | undefined
  v2Trade: Trade | undefined
  inputError?: string
  inputCurrencyBalanceQuery: UseQueryResult<CurrencyAmount | undefined | null, unknown>
  outputCurrencyBalanceQuery: UseQueryResult<CurrencyAmount | undefined | null, unknown>
} {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const chainId = useChainId()

  const {
    independentField,
    typedValue,
    [CurrencyDirection.INPUT]: { currencyId: inputCurrencyId },
    [CurrencyDirection.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
  } = useSwapState()

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const to: string | null = (recipient || account) ?? null
  const inputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(inputCurrency ?? undefined)
  const outputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(outputCurrency ?? undefined)

  const isExactIn: boolean = independentField === CurrencyDirection.INPUT
  const parsedAmount = tryParseAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined, chainId)

  const bestTradeExactIn = useTradeExactIn(isExactIn ? parsedAmount : undefined, outputCurrency ?? undefined)
  const bestTradeExactOut = useTradeExactOut(inputCurrency ?? undefined, !isExactIn ? parsedAmount : undefined)

  const v2Trade = isExactIn ? bestTradeExactIn : bestTradeExactOut

  const currencyBalances = {
    [CurrencyDirection.INPUT]: inputCurrencyBalanceQuery.data,
    [CurrencyDirection.OUTPUT]: outputCurrencyBalanceQuery.data,
  }

  const currencies: { [field in CurrencyDirection]?: Currency } = {
    [CurrencyDirection.INPUT]: inputCurrency ?? undefined,
    [CurrencyDirection.OUTPUT]: outputCurrency ?? undefined,
  }

  const isWrongAppNetwork = !SUPPORTED_NETWORKS_IDS.includes(chainId)

  let inputError: string | undefined
  if (!account || isWrongAppNetwork) {
    inputError = 'Connect Wallet'
  }

  if (!parsedAmount) {
    inputError = inputError ?? t('common:enterAmount') ?? ''
  }

  if (!currencies[CurrencyDirection.INPUT] || !currencies[CurrencyDirection.OUTPUT]) {
    inputError = inputError ?? 'Select a token'
  }

  const formattedTo = isAddress(to)
  if (!to || !formattedTo) {
    inputError = inputError ?? 'Enter a recipient'
  } else {
    if (
      BAD_RECIPIENT_ADDRESSES.indexOf(formattedTo) !== -1 ||
      (bestTradeExactIn && involvesAddress(bestTradeExactIn, formattedTo)) ||
      (bestTradeExactOut && involvesAddress(bestTradeExactOut, formattedTo))
    ) {
      inputError = inputError ?? 'Invalid recipient'
    }
  }

  const [allowedSlippage] = useUserSlippageTolerance()

  const slippageAdjustedAmounts =
    v2Trade && allowedSlippage && computeSlippageAdjustedAmounts(v2Trade, allowedSlippage, chainId)

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [
    currencyBalances[CurrencyDirection.INPUT],
    slippageAdjustedAmounts ? slippageAdjustedAmounts[CurrencyDirection.INPUT] : null,
  ]

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = 'Insufficient ' + amountIn.currency.getSymbol() + ' balance'
  }

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    v2Trade: v2Trade ?? undefined,
    inputError,
    inputCurrencyBalanceQuery,
    outputCurrencyBalanceQuery,
  }
}

export function useDerivedSwapAggregatorInfo(): {
  currencies: { [field in CurrencyDirection]?: Currency }
  currencyBalances: { [field in CurrencyDirection]?: CurrencyAmount | null }
  parsedAmount: CurrencyAmount | undefined
  inputError?: string
  inputCurrencyBalanceQuery: UseQueryResult<CurrencyAmount | undefined | null, unknown>
  outputCurrencyBalanceQuery: UseQueryResult<CurrencyAmount | undefined | null, unknown>
} {
  const { account } = useActiveWeb3React()
  const chainId = useChainId()
  const location = useLocation()

  const {
    independentField,
    typedValue,
    [CurrencyDirection.INPUT]: { currencyId: inputCurrencyId },
    [CurrencyDirection.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
  } = useSwapState()

  const inputCurrency = useCurrency(inputCurrencyId, 'Aggregator')
  const outputCurrency = useCurrency(outputCurrencyId, 'Aggregator')
  const to: string | null = (recipient || account) ?? null
  const inputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(inputCurrency ?? undefined)
  const outputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(outputCurrency ?? undefined)

  const isExactIn: boolean = independentField === CurrencyDirection.INPUT
  const parsedAmount = tryParseAmount(
    typedValue?.toString(),
    (isExactIn ? inputCurrency : outputCurrency) ?? undefined,
    chainId
  )

  const currencyBalances = {
    [CurrencyDirection.INPUT]: inputCurrencyBalanceQuery.data,
    [CurrencyDirection.OUTPUT]: outputCurrencyBalanceQuery.data,
  }

  const currencies: { [field in CurrencyDirection]?: any } = {
    [CurrencyDirection.INPUT]: inputCurrency?.address
      ? inputCurrency
      : listedTokensList[chainId].find((item: Currency) => item.symbol === NATIVE_CURRENCY[chainId].symbol),
    [CurrencyDirection.OUTPUT]:
      outputCurrency ?? listedTokensList[chainId].find((item: Currency) => item.symbol === Currency.LIF3.symbol),
  }

  const searchParams = new URLSearchParams(location.search)
  const outputCurrencyAddress = searchParams.get('outputCurrency')
  if (!currencies[CurrencyDirection.OUTPUT]) {
    const tokens = listedTokensList[chainId]
    const currency = tokens.find((el) => el.address.toLowerCase() === outputCurrencyAddress?.toLowerCase())
    currency && (currencies[CurrencyDirection.OUTPUT] = currency)
  }

  if (currencies.OUTPUT && currencies.INPUT?.address === currencies.OUTPUT?.address) {
    currencies.OUTPUT = undefined
  }

  const isWrongAppNetwork = !SUPPORTED_NETWORKS_IDS.includes(chainId)

  let inputError: string | undefined
  if (!account || isWrongAppNetwork) {
    inputError = 'Connect Wallet'
  }

  if (!parsedAmount) {
    inputError = inputError ?? ['0', ''].includes(typedValue) ? 'Enter an amount' : 'Invalid amount'
  }

  if (!currencies[CurrencyDirection.INPUT] || !currencies[CurrencyDirection.OUTPUT]) {
    inputError = inputError ?? 'Select a token'
  }

  // compare input balance to max input based on version
  const numericBalance = Number(currencyBalances[CurrencyDirection.INPUT]?.toExact())
  const numericAmount = Number(parsedAmount?.toExact())
  if (numericBalance < numericAmount) {
    inputError = 'Insufficient ' + currencies[CurrencyDirection.INPUT].symbol + ' balance'
  }

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    inputError,
    inputCurrencyBalanceQuery,
    outputCurrencyBalanceQuery,
  }
}

function parseCurrencyFromURLParameter(urlParam: any, chainId: number, isZeroMainBalance?: boolean): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam)
    if (valid) return valid
    if (urlParam.toUpperCase() === NATIVE_CURRENCY[chainId].symbol) return NATIVE_CURRENCY[chainId].symbol

    if (valid === false) return NATIVE_CURRENCY[chainId].symbol
  }

  return NATIVE_CURRENCY[chainId].symbol
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): CurrencyDirection {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output'
    ? CurrencyDirection.OUTPUT
    : CurrencyDirection.INPUT
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = isAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

export function queryParametersToSwapState(
  parsedQs: ParsedQs,
  isZeroMainBalance: boolean = false,
  chainId: number,
  didChainChange: boolean
): SwapState {
  const getOutputValue = () => {
    if (didChainChange) {
      return PROJECT_TOKEN_ADDRESS[chainId]
    }

    if (!parsedQs.outputCurrency && isZeroMainBalance) {
      return PROJECT_TOKEN_ADDRESS[chainId]
    }

    return parsedQs.outputCurrency ?? PROJECT_TOKEN_ADDRESS[chainId]
  }

  const getInputValue = () => {
    if (didChainChange) {
      return NATIVE_CURRENCY[chainId].symbol
    }

    return parsedQs.inputCurrency ?? NATIVE_CURRENCY[chainId].symbol
  }

  let inputCurrency = parseCurrencyFromURLParameter(getInputValue(), chainId)
  let outputCurrency = parseCurrencyFromURLParameter(getOutputValue(), chainId)

  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === 'string') {
      inputCurrency = ''
    } else {
      outputCurrency = ''
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient)

  return {
    [CurrencyDirection.INPUT]: {
      currencyId: inputCurrency,
    },
    [CurrencyDirection.OUTPUT]: {
      currencyId: outputCurrency,
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient,
    limitValue: '',
    selectedSwapType: 'Aggregator',
  }
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch(
  didChainChange: boolean
): { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined {
  const chainId = useChainId()
  const dispatch = useDispatch<AppDispatch>()
  const parsedQs = useParsedQueryString()
  const [result, setResult] = useState<
    { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined
  >()

  useEffect(() => {
    const parsed = queryParametersToSwapState(parsedQs, false, chainId, didChainChange)

    dispatch(
      replaceSwapState({
        typedValue: didChainChange ? '' : parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[CurrencyDirection.INPUT].currencyId,
        outputCurrencyId: parsed[CurrencyDirection.OUTPUT].currencyId,
        recipient: parsed.recipient,
      })
    )

    setResult({
      inputCurrencyId: parsed[CurrencyDirection.INPUT].currencyId,
      outputCurrencyId: parsed[CurrencyDirection.OUTPUT].currencyId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId])

  return result
}

export const useSwap = (amountIn: string | undefined) => {
  const { account, library } = useActiveWeb3React()
  const firebirdQuoteQuery = useGetFirebirdQuote()

  const addTransaction = useTransactionAdder()

  const chainId = useChainId()

  const swap = async () => {
    const encodedData = firebirdQuoteQuery.data?.encodedData

    const currencyAIsEth =
      firebirdQuoteQuery.data?.maxReturn.from.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
    const currencyBIsEth =
      firebirdQuoteQuery.data?.maxReturn.to.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

    const tokenFrom =
      firebirdQuoteQuery.data?.maxReturn.tokens[
        currencyAIsEth ? WNATIVE[chainId].address.toLowerCase() : firebirdQuoteQuery.data.maxReturn.from.toLowerCase()
      ]
    const tokenTo =
      firebirdQuoteQuery.data?.maxReturn.tokens[
        currencyBIsEth ? WNATIVE[chainId].address.toLowerCase() : firebirdQuoteQuery.data.maxReturn.to.toLowerCase()
      ]

    if (!encodedData || !account || !library || !tokenFrom || !tokenTo) return

    const routerContract = encodedData?.router // router contract address

    const txRequest = {
      from: account ?? '', // signer address
      to: routerContract,
      gasLimit: ethers.utils.hexlify(1600000),
      data: encodedData.data, // encoded contract data
      value: currencyAIsEth ? amountIn : undefined,
    }

    const tx = await library?.getSigner()?.sendTransaction(txRequest)

    const amountFrom = fromWei(Number(firebirdQuoteQuery.data?.maxReturn.totalFrom), tokenFrom?.decimals).toPrecision(5)
    const amountTo = fromWei(Number(firebirdQuoteQuery.data?.maxReturn.totalTo), tokenTo?.decimals).toPrecision(5)

    addTransaction(tx, {
      summary: `Swap ${amountFrom} ${tokenFrom?.symbol} for ${amountTo} ${tokenTo?.symbol}`,
    })

    return tx
  }

  return { swap }
}

// const useSwap1Inch = () => {
//   const chainId = useChainId()
//   const { account, library } = useWeb3React()
//   const { typedValue } = useSwapState()
//   const { inputCurrency, wrappedInputCurrency, wrappedOutputCurrency, outputCurrency } = useSelectedCurrencies()
//   const addPopup = useAddPopup()
//   const router1Inch = ROUTER_ADDRESSES_1INCH[chainId]

//   const from =
//     inputCurrency === NATIVE_CURRENCY[chainId]
//       ? '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
//       : wrappedInputCurrency?.address

//   const to =
//     outputCurrency === NATIVE_CURRENCY[chainId]
//       ? '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
//       : wrappedOutputCurrency?.address

//   const simpleSwap = async (amount) => {
//     if (!from || !to || !account) return

//     const routeOptions = {
//       fee: 0.005, // 5%,
//       integrator: process.env.REACT_APP_LIFI_INTEGRATOR,
//       exchanges: {
//         allow: [Exchanges.ODOS], // todo [Exchanges.ONEINCH]
//       },
//     }

//     const fromAmountInWei = formatByDecimals(Number(amount), inputCurrency?.decimals as number)

//     const routesRequest = {
//       fromChainId: chainId,
//       fromAmount: fromAmountInWei.toString(),
//       fromTokenAddress: from,
//       toChainId: chainId,
//       toTokenAddress: to,
//       options: routeOptions,
//       referal: process.env.REACT_APP_LIFI_REFERAL,
//     }
//     const result = await lifi.getRoutes(routesRequest)

//     if (result?.routes.length === 0) {
//       throw Error('The selected route is empty. Please navigate to other available routes.')
//     }

//     const signer = library.getSigner()

//     const swapTransaction = await lifi.executeRoute(signer, result?.routes?.[0])

//     return swapTransaction
//   }

//   const swap1Inch = async () => {
//     if (!from || !to || !account) return
//     if (!router1Inch || chainId !== ChainId.ETHEREUM) {
//       addPopup({
//         notification: {
//           text: 'Swap not supported on this chain',
//           success: false,
//         },
//       })
//       return
//     }

//     // TO DO: Remove when change DEV plan for 1Inch (1 Request per second)
//     await new Promise((resolve) => setTimeout(resolve, 1000))

//     const swapParams = generate1InchSwapParmas(from, to, Number(typedValue), account, 1)

//     const swapTransaction = await buildTxForSwap1Inch(swapParams, chainId)

//     // TO DO: Remove when change DEV plan for 1Inch (1 Request per second)
//     await new Promise((resolve) => setTimeout(resolve, 1000))

//     try {
//       const tx = {
//         from: account ?? '',
//         to: router1Inch,
//         data: swapTransaction.data,
//         ...(swapTransaction.value && !isZero(swapTransaction.value) ? { value: toHex(swapTransaction.value) } : {}),
//       }
//       const response = await getSigner(library, account)
//         .estimateGas(tx)
//         .then((estimate) => {
//           const newTxn = {
//             ...tx,
//             gasLimit: calculateGasMargin(estimate),
//           }

//           return getSigner(library, account)
//             .sendTransaction(newTxn)
//             .then((response) => {
//               if (!response.hash) {
//                 throw new Error(
//                   `Your swap was modified through your wallet. If this was a mistake, please cancel immediately or risk losing your funds.`
//                 )
//               }
//               return response
//             })
//         })

//       return response
//     } catch (err) {
//       console.error(err)
//       return
//     }
//   }

//   return { swap1Inch, simpleSwap }
// }

export const useSwapODOS = () => {
  const chainId = useChainId()
  const { account, library } = useWeb3React()

  const { inputCurrency, outputCurrency, wrappedInputCurrency, wrappedOutputCurrency } = useSelectedCurrencies()
  const [allowedSlippage] = useUserSlippageTolerance()

  const getCurrentAllowance = async (tokenAddress: string, spenderAddress: string, signer: any) => {
    try {
      const tokenContract = new ethers.Contract(tokenAddress, ERC20ApproveAbi, signer)

      const currentAllowance = await tokenContract.allowance(account ?? '', spenderAddress)
      const formattedAllowance = ethers.utils.formatUnits(currentAllowance, 18)

      return formattedAllowance
    } catch (error) {
      return undefined
    }
  }

  const approveToken = async (tokenAddress: string, spenderAddress: string, amount: string, signer: any) => {
    try {
      const tokenContract = new ethers.Contract(tokenAddress, ERC20ApproveAbi, signer)
      const fullNumber = Number(amount)?.toLocaleString('fullwide', { useGrouping: false })
      const transaction = await tokenContract.approve(spenderAddress, fullNumber)

      return await transaction.wait()
    } catch (error) {
      console.error('Error during approval:', error)
    }
  }

  const simpleSwap = async (amount: string) => {
    const quoteUrl = `${odosBaseUrl}/sor/quote/v2`

    const nativeAddress = '0x0000000000000000000000000000000000000000'
    const emptyRouteMessage = 'The selected route is empty. Please navigate to other available routes.'

    const from = (inputCurrency === NATIVE_CURRENCY[chainId] ? nativeAddress : wrappedInputCurrency?.address) ?? ''

    const to = (outputCurrency === NATIVE_CURRENCY[chainId] ? nativeAddress : wrappedOutputCurrency?.address) ?? ''

    if (!from || !to) return

    const fromAmountInWei = formatByDecimals(Number(amount), inputCurrency?.decimals as number)
    const quoteRequestBody = {
      chainId,
      compact: true,
      inputTokens: [
        {
          amount: fromAmountInWei.toString(),
          tokenAddress: from,
        },
      ],
      outputTokens: [
        {
          proportion: 1,
          tokenAddress: to,
        },
      ],
      referralCode: chainId === ChainId.POLYGON ? polygonReferralCode : 0,
      slippageLimitPercent: allowedSlippage / 100,
      sourceBlacklist: [],
      sourceWhitelist: [],
      userAddr: account,
    }

    try {
      const headers = { 'Content-Type': 'application/json' }
      const quoteResponse = await axios.post(quoteUrl, quoteRequestBody, {
        headers,
      })

      if (quoteResponse.status === 200) {
        const quote = quoteResponse.data

        const assembleUrl = `${odosBaseUrl}/sor/assemble`
        const assembleRequestBody = {
          userAddr: account,
          pathId: quote.pathId,
          simulate: true,
        }

        const assembleResponse = await axios.post(assembleUrl, assembleRequestBody, {
          headers,
        })
        if (assembleResponse.status === 200) {
          const { transaction } = assembleResponse.data
          const { gasPrice, value, to, from, nonce, chainId, data } = transaction

          const transactionData = {
            gasLimit: quote.gasEstimate,
            gasPrice,
            value,
            to,
            from,
            nonce,
            chainId,
            data,
          }

          const signedTx: any = await getSigner(library, account as string)
            .sendTransaction(transactionData)
            .catch((err) => {
              return { error: err }
            })

          if (signedTx.error) {
            return signedTx
          }

          const receipt = await signedTx.wait()
          return receipt
        } else {
          return { error: { message: emptyRouteMessage } }
        }
      } else {
        return { error: { message: emptyRouteMessage } }
      }
    } catch (error: any) {
      return { error }
    }
  }

  return { approveToken, simpleSwap, getCurrentAllowance }
}
