import { Contract } from '@ethersproject/contracts'
import { ChainId, WNATIVE } from 'sdk'
import { UniswapV2PairAbi } from 'shared/abi/UniswapV2PairAbi'
import { useMemo } from 'react'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS,
} from '../constants/abis/argent-wallet-detector'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ValidatorsStakingAbi from 'constants/abis/validators-staking-abi.json'
import ValidatorsDistributionAbi from 'constants/abis/validators-distribution-abi.json'
import ERC20_ABI from '../constants/abis/erc20.json'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import WETH_ABI from '../constants/abis/weth.json'
import { V1_EXCHANGE_ABI } from '../constants/v1'
import { getContract } from '../utils'
import { useActiveWeb3React, useChainId } from './index'
import {
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  QUOTER_ADDRESSES,
  STAKING_REWARDS_ADDRESSES,
} from 'constants/v3/addresses'
import StakingRewardsV3ABI from 'constants/abis/staking_rewards_v3.json'

import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import QuoterV2Json from '@uniswap/swap-router-contracts/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json'
import {
  VALIDATORS_DISTRIBUTION_CONTRACT_ADDRESS,
  VALIDATORS_STAKING_CONTRACT_ADDRESS,
} from 'pages/Validators/constants'

const { abi: QuoterV2ABI } = QuoterV2Json
const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson

// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}

export function useV1ExchangeContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, V1_EXCHANGE_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const chainId = useChainId()
  return useContract(chainId ? WNATIVE[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.ETHEREUM ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, UniswapV2PairAbi, withSignerIfPossible)
}

export function useValidatorsStakingContract(withSignerIfPossible?: boolean): Contract | null {
  return useContract(VALIDATORS_STAKING_CONTRACT_ADDRESS, ValidatorsStakingAbi, withSignerIfPossible)
}

export function useValidatorsDistributionContract(withSignerIfPossible?: boolean): Contract | null {
  return useContract(VALIDATORS_DISTRIBUTION_CONTRACT_ADDRESS, ValidatorsDistributionAbi, withSignerIfPossible)
}

export function useV3NFTPositionManagerContract(withSignerIfPossible?: boolean): Contract | null {
  const chainId = useChainId()

  const contract = useContract(
    NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId],
    NFTPositionManagerABI,
    withSignerIfPossible
  )

  return contract
}

export function useV3StakingRewardsContract(withSignerIfPossible?: boolean): Contract | null {
  const chainId = useChainId()

  const contract = useContract(STAKING_REWARDS_ADDRESSES[chainId], StakingRewardsV3ABI, withSignerIfPossible)

  return contract
}

export function useQuoter() {
  const chainId = useChainId()

  return useContract(QUOTER_ADDRESSES[chainId], QuoterV2ABI)
}
