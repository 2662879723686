import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from './NetworkConnector'

import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ChainId } from 'sdk'

export const RPC = {
  [ChainId.FANTOM]: 'https://fantom.drpc.org',
  [ChainId.TOMBCHAINTESTNET]: 'https://testtomb.tombchain.com/',
  [ChainId.TOMB]: 'https://rpc.tombchain.com/',
  [ChainId.BSC]: 'https://bsc-dataseed.binance.org/',
  [ChainId.POLYGON]: 'https://polygon-bor-rpc.publicnode.com',
  [ChainId.AMOY_TESTNET]: 'https://polygon-amoy.drpc.org',
  [ChainId.ETHEREUM]: 'https://eth.llamarpc.com',
  // [ChainId.OPTIMISM]: `${ankrBaseUrl}/optimism/${ankrKey}`,
  // [ChainId.ARBITRUM]: `${ankrBaseUrl}/arbitrum/${ankrKey}`,
  [ChainId.BASE]: 'https://base-mainnet.public.blastapi.io',
  [ChainId.LIF3CHAIN_TESTNET]: 'https://testnet-evm.lif3.com/',
  [ChainId.LIF3CHAIN]: 'https://rpc.lif3.com/',
}

const connections = {
  [ChainId.FANTOM]: {
    url: RPC[ChainId.FANTOM],
  },
  [ChainId.TOMBCHAINTESTNET]: {
    url: RPC[ChainId.TOMBCHAINTESTNET],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.TOMB]: {
    url: RPC[ChainId.TOMB],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.BSC]: {
    url: RPC[ChainId.BSC],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.POLYGON]: {
    url: RPC[ChainId.POLYGON],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.AMOY_TESTNET]: {
    url: RPC[ChainId.AMOY_TESTNET],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.ETHEREUM]: {
    url: RPC[ChainId.ETHEREUM],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.OPTIMISM]: {
    url: RPC[ChainId.OPTIMISM],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.ARBITRUM]: {
    url: RPC[ChainId.ARBITRUM],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.BASE]: {
    url: RPC[ChainId.BASE],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.LIF3CHAIN_TESTNET]: {
    url: RPC[ChainId.LIF3CHAIN_TESTNET],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.LIF3CHAIN]: {
    url: RPC[ChainId.LIF3CHAIN],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
}

export const PROVIDERS_BY_CHAIN = {
  [ChainId.TOMBCHAINTESTNET]: new StaticJsonRpcProvider(
    connections[ChainId.TOMBCHAINTESTNET],
    ChainId.TOMBCHAINTESTNET
  ),
  [ChainId.TOMB]: new StaticJsonRpcProvider(connections[ChainId.TOMB], ChainId.TOMB),
  [ChainId.FANTOM]: new StaticJsonRpcProvider(connections[ChainId.FANTOM], ChainId.FANTOM),
  [ChainId.BSC]: new StaticJsonRpcProvider(connections[ChainId.BSC], ChainId.BSC),
  [ChainId.POLYGON]: new StaticJsonRpcProvider(connections[ChainId.POLYGON], ChainId.POLYGON),
  [ChainId.AMOY_TESTNET]: new StaticJsonRpcProvider(connections[ChainId.AMOY_TESTNET], ChainId.AMOY_TESTNET),
  [ChainId.ETHEREUM]: new StaticJsonRpcProvider(connections[ChainId.ETHEREUM], ChainId.ETHEREUM),
  [ChainId.OPTIMISM]: new StaticJsonRpcProvider(connections[ChainId.OPTIMISM], ChainId.OPTIMISM),
  [ChainId.ARBITRUM]: new StaticJsonRpcProvider(connections[ChainId.ARBITRUM], ChainId.ARBITRUM),
  [ChainId.BASE]: new StaticJsonRpcProvider(connections[ChainId.BASE], ChainId.BASE),
  [ChainId.LIF3CHAIN_TESTNET]: new StaticJsonRpcProvider(
    connections[ChainId.LIF3CHAIN_TESTNET],
    ChainId.LIF3CHAIN_TESTNET
  ),
  [ChainId.LIF3CHAIN]: new StaticJsonRpcProvider(connections[ChainId.LIF3CHAIN], ChainId.LIF3CHAIN),
}

export const getNetwork = (chainId: number) => {
  PROVIDERS_BY_CHAIN[chainId]._pollingInterval = 250

  return new NetworkConnector({
    defaultChainId: chainId,
    urls: RPC,
  })
}

export const SUPPORTED_NETWORKS_IDS = [
  ChainId.FANTOM,
  ChainId.BSC,
  ChainId.POLYGON,
  ChainId.AMOY_TESTNET,
  ChainId.ETHEREUM,
  // temporarily unavailable chains
  // ChainId.OPTIMISM,
  // ChainId.ARBITRUM,
  // ChainId.BASE,
  ChainId.LIF3CHAIN_TESTNET,
  ChainId.LIF3CHAIN,
]

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_NETWORKS_IDS,
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: RPC,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  supportedChainIds: SUPPORTED_NETWORKS_IDS,
})
