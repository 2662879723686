import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { rgba } from 'polished'
import { ReactComponent as DropDownIcon } from 'assets/images/dropdown.svg'
import { CommunityDropdownBox } from './components/CommunityDropdown/styles'
import { TradeDropdownBox } from './components/TradeDropdown/styles'
import { EarnDropdownBox } from './components/EarnDropdown/styles'
import { BuyDropdownBox } from './components/BuyDropdown/styles'
import { EcosystemDropdownBox } from './components/EcosystemDropdown/styles'

export const HeaderFrame = styled.div`
  position: relative;
  top: 0;
  z-index: 3;

  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 8px 20px;
  height: 60px;
  border-radius: 0 0 8px 8px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 20px 40px, rgba(0, 0, 0, 0.094) 0px 10.125px 17.4375px,
    rgba(0, 0, 0, 0.07) 0px 4px 6.5px, rgba(0, 0, 0, 0.047) 0px 0.875px 2.3125px;

  @media only screen and (max-width: 460px) {
    height: auto;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 8px 10px;
    gap: 6px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: space-between;
    box-shadow: none;
    gap: 15px;
  `};

  &::after {
    content: '';
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 49.48%,
      rgba(211, 211, 211, 0.08) 69.79%,
      rgba(255, 255, 255, 0.08) 100%
    );
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 0 0 8px 8px;

    ${({ theme }) => theme.mediaWidth.upToMedium`
      display: none;
    `};
  }
`

export const Title = styled.div`
  position: relative;
  display: flex;
  justify-self: flex-start;
  align-items: center;

  pointer-events: auto;

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

export const LogoDesktop = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    width: 108px;
    height: 45px;

    ${({ theme }) => theme.mediaWidth.upToLarge`
      width: 45px;
      height: 36px;
    `};
  }
`

export const HeaderLinks = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

export const StLinkIcon = styled.div`
  width: 11px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    display: none;
  `};
`

export const StLinkDropIcon = styled(StLinkIcon)`
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    display: flex;
  `};
`

export const StLink = styled(Link)<{ $isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  position: relative;
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryText1};
  width: fit-content;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  gap: 8px;
  transition: all 0.3s;
  align-items: center;
  padding: 8px 17px;
  min-height: 60px;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;

    background: ${({ theme }) => theme.activeTabBg};
    content: '';
  }

  &::before {
    position: absolute;
    left: 1px;
    top: calc(100% - 3px);

    width: 100%;
    height: 1px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, #fff 47.92%, rgba(255, 255, 255, 0) 100%);
    opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
    content: '';
    transition: transform 0.3s, opacity 0.5s;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &:hover:after {
    opacity: 1;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 8px 12px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 13px;
    padding: 8px;
  `};
`

export const StLinkText = styled.span`
  position: relative;
`

export const StyledDropBtn = styled.button<{ $isActive: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  position: relative;
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryText1};
  width: fit-content;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  transition: all 0.3s;
  align-items: center;
  padding: 8px 17px;
  min-height: 60px;
  border: none;
  background: transparent;
  gap: 8px;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
    opacity: 0;
    background: ${({ theme }) => theme.activeTabBg};
    transition: opacity 0.3s;
    content: '';
  }

  &::before {
    position: absolute;
    left: 1px;
    top: calc(100% - 3px);

    width: 100%;
    height: 1px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, #fff 47.92%, rgba(255, 255, 255, 0) 100%);
    opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
    content: '';
    transition: transform 0.3s, opacity 0.5s;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &:hover:after {
    opacity: 1;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 8px 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 8px 12px;
  `};
`

export const StyledDropIcon = styled(DropDownIcon)<{ selected: boolean }>`
  height: 35%;
  transform: ${({ selected }) => (selected ? 'rotate(180deg)' : 'rotate(0deg)')};

  path {
    stroke: ${({ theme }) => theme.primaryText1};
    stroke-width: 1.5px;
  }
`

export const MenuFlyout = styled.span`
  min-width: 160px;
  width: fit-content;
  background: ${({ theme }) => theme.componentBg4};
  box-shadow: ${({ theme }) => theme.contrastShadow};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 5px);
  right: 0px;
  z-index: 100;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
`

export const OtherFlyout = styled(MenuFlyout)`
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 0;
  gap: 5px;
`

export const MenuItemRouteLink = styled(Link)`
  font-size: 15px;
  text-decoration: none;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 10px 30px;
  transition: 0.3s all;
  white-space: nowrap;
  color: ${({ theme }) => theme.primaryText1};

  &:hover {
    color: ${({ theme }) => theme.secondaryText1};
  }
`

export const HeaderMobileLink = styled(Link)`
  display: none;
  text-decoration: none;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    flex-shrink: 1;
    justify-content: center;
  `};
`

export const LogoMobile = styled.div`
  display: none;

  & > svg {
    width: 128px;
    height: 55px;

    @media screen and (max-width: 768px) {
      width: 90px;
      height: 36px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    width: 100%:
    justify-content: center;
  `};
`

export const HeaderMobileRow = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
  `};
`

export const HeaderSide = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
  }
`

export const CarouselWrapperDesktop = styled.div`
  display: flex;
  flex: 0 0 auto;
`

export const MenuItemLink = styled.a`
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  gap: 8px;
  transition: 0.3s all;
  white-space: nowrap;
  color: ${({ theme }) => theme.primaryText1};

  &:hover {
    color: ${({ theme }) => theme.secondaryText1};
  }
`

export const GlobalSettignsBtn = styled.button`
  width: 35px;
  height: 35px;
  padding: 6px 8px;

  border: 0.5px solid rgba(255, 255, 255, 0.6);
  background: linear-gradient(95deg, rgba(255, 255, 255, 0.1) 2.05%, rgba(255, 255, 255, 0.05) 96.48%);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);

  border-radius: 5px;

  transition: opacity 0.2;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const CommunityWrapper = styled.div`
  position: relative;

  &:hover ${CommunityDropdownBox} {
    visibility: visible;
    opacity: 1;
    transform: translateX(0) scale(1);

    @media screen and (max-width: 1510px) {
      transform: translateX(0) scale(1);
    }

    ${({ theme }) => theme.mediaWidth.upToLarge`
      transform: translateX(10%) scale(1);

      &::after {
        right: calc(63% - 90px);
      }
    `};
  }
`

export const TradeWrapper = styled.div`
  position: relative;

  &:hover ${TradeDropdownBox} {
    visibility: visible;
    opacity: 1;
    transform: translateX(0) scale(1);

    @media screen and (max-width: 1510px) {
      transform: translateX(0) scale(1);
    }
  }
`

export const EcosWrapper = styled.div`
  position: relative;

  &:hover ${EcosystemDropdownBox} {
    visibility: visible;
    opacity: 1;
    transform: translateX(0) scale(1);

    @media screen and (max-width: 1510px) {
      transform: translateX(0) scale(1);
    }
  }
`

export const EarnWrapper = styled.div`
  position: relative;

  &:hover ${EarnDropdownBox} {
    visibility: visible;
    opacity: 1;
    transform: translateX(0) scale(1);

    @media screen and (max-width: 1510px) {
      transform: translateX(0) scale(1);
    }
  }
`

export const BuyWrapper = styled.div`
  position: relative;

  &:hover ${BuyDropdownBox} {
    visibility: visible;
    opacity: 1;
    transform: translateX(0) scale(1);

    @media screen and (max-width: 1510px) {
      transform: translateX(0) scale(1);
    }
  }
`
