import axios from 'axios'
import { useQuery } from 'react-query'
import { useChainId } from 'hooks'
import { api } from 'shared'

const CGTokenIds = {
  // LIF3: 'lif3-2',
  LIF3: 'lif3',
  'LIF3 (OLD)': 'lif3',
  // LSHARE: 'lif3-lshare-new',
  LSHARE: 'lif3-lshare',
  'LSHARE (OLD)': 'lif3-lshare',
  FTM: 'fantom',
  BNB: 'binancecoin',
  ETH: 'ethereum',
  BTC: 'bitcoin',
  OP: 'optimism',
  ARB: 'arbitrum',
  MATIC: 'matic-network',
} as const

type TokenSymbols = keyof typeof CGTokenIds
type CGTokenValues = (typeof CGTokenIds)[TokenSymbols]

export type UseCGTokenPricesProps = {
  tokenSymbols: string[]
  iniciator?: string
}

export const useCGTokenPrices = ({ tokenSymbols, iniciator = 'common' }: UseCGTokenPricesProps) => {
  const chainId = useChainId()

  return useQuery(
    ['use-coingecko-prices', iniciator, chainId, ...tokenSymbols],
    async () => {
      try {
        const ids = tokenSymbols.reduce((acc, cur) => {
          if (cur) {
            return `${acc}${acc.length ? ',' + CGTokenIds[cur] : CGTokenIds[cur]}`
          }

          return acc
        }, '')

        if (!ids.length) {
          return
        }

        const response = await api.coingeckoApi.get<{
          [key in CGTokenValues]: {
            usd: number
          }
        }>('/simple/price/', {
          params: {
            ids: ids,
            vs_currencies: 'usd',
          },
        })

        return Object.fromEntries(
          Object.entries(response.data).map(([CSTokenSymbol, CSPriceObject]) => {
            const currentTokenSymbol = Object.keys(CGTokenIds).find(
              (tokenSymbol) => CGTokenIds[tokenSymbol] === CSTokenSymbol
            )

            return [currentTokenSymbol, CSPriceObject]
          })
        )
      } catch (error) {
        console.error(error)
      }
    },
    {
      enabled: Boolean(chainId),
      refetchOnWindowFocus: false,
      refetchInterval: 60_000,
    }
  )
}
