import { ChainId } from 'sdk'

export const V3_CORE_FACTORY_ADDRESSES = {
  [ChainId.FANTOM]: '0x5Af34712aE630b7754764a81D815A56963e6214a',
  [ChainId.ETHEREUM]: '0x129538ee65a692fa041e107921d716c31d186803',
  [ChainId.POLYGON]: '0x3BBCCA7B3eE96718ADF6178bF17d90684ee14745',
  [ChainId.LIF3CHAIN_TESTNET]: '0x83e6f2858e6eBBF934a354CaF552A07Ee04D511e',
  [ChainId.BSC]: '0xc84ab9ABCCc3F35D5F8bDD328ed7e20Bc5b81ce4',
}

export const POOL_INIT_CODE_HASH = {
  [ChainId.FANTOM]: '0x1b119fef4adc071cd75f23ea764c138d56e57db5050bddcb7708a3b47790d447',
  [ChainId.POLYGON]: '0x88ea7049e70592b519fc358b446165d4dbedb71ff7c3749add6dc16731e6cd5a',
  [ChainId.ETHEREUM]: '0x88ea7049e70592b519fc358b446165d4dbedb71ff7c3749add6dc16731e6cd5a',
  [ChainId.LIF3CHAIN_TESTNET]: '0x88ea7049e70592b519fc358b446165d4dbedb71ff7c3749add6dc16731e6cd5a',
  [ChainId.BSC]: '0x88ea7049e70592b519fc358b446165d4dbedb71ff7c3749add6dc16731e6cd5a',
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = {
  [ChainId.FANTOM]: '0xaF46F3b96ae15c96B76369e27d5380A844d4e407',
  [ChainId.POLYGON]: '0x08bfC5452e7141590073002a66EE6a1fF6B06Fe0',
  [ChainId.ETHEREUM]: '0x15823A4Df9967d0b4440e4183b4c931AfD85DAce',
  [ChainId.LIF3CHAIN_TESTNET]: '0xCa588BEF686D302fc83a666645f1c4372C7B1850',
  [ChainId.BSC]: '0x486F754E3640c4b39E7EcB21356eD34cFF4808ba',
}

export const STAKING_REWARDS_ADDRESSES = {
  // [ChainId.FANTOM]: '0x0439059DBF4EB3c2a3eE5f88037e93dCDAbA9A2B',
  // [ChainId.FANTOM]: '0xFE7aad50cA16CB5A955926902e1f713908d49EB6',
  [ChainId.FANTOM]: '0x6eea3895f41A742cE8214cd5D05619aD1058e10E',
  // [ChainId.POLYGON]: '0xBA68BbA9C36d4FA946D256F29cC77813445F2F1D',
  // [ChainId.POLYGON]: '0x99e2bc9a09f723ccE52DaaA54D3cEA1b34A34b19',
  [ChainId.POLYGON]: '0xE47C3e3238346e76DCde9Dd677B26FEb8340b634',
  // [ChainId.ETHEREUM]: '0x0cA93B40992a3d5cE52fc7e0636b4E5593fA3Ac7',
  [ChainId.ETHEREUM]: '0x01090FBC572c5417379c680264f555CD7260b4AA',
  [ChainId.BSC]: '0xA22A5a34673758681DC6B8bef78E7856a58DA28D',
}

export const QUOTER_ADDRESSES = {
  [ChainId.FANTOM]: '0x93C5d13D2D13A56b4055C6D6D2851d784e94E22B',
  [ChainId.POLYGON]: '0x43642b6866f0daB10910a8A123Be4393F8B72baa',
  [ChainId.ETHEREUM]: '0x8973670CC2DcBC5A03C8Ae373d9d3754b13faf69',
  [ChainId.LIF3CHAIN_TESTNET]: '0x8cb883071401dccfb73bDbCC8EfEC66c66cdbDE4',
  [ChainId.BSC]: '0x64563052C9A81d3D9F5475bcC60fbC24FA0F042c',
}

export const TICK_LENS_ADDRESSES = {
  [ChainId.FANTOM]: '',
  [ChainId.ETHEREUM]: '',
}

export const PERMIT2_ADDRESS = {
  [ChainId.FANTOM]: '0x07ed33a242bd9c08ca3c198e01189e35265024da',
  [ChainId.POLYGON]: '',
  [ChainId.ETHEREUM]: '',
  [ChainId.BSC]: '',
}

export const ROUTER_ADDRESSES = {
  [ChainId.FANTOM]: '0xe46eAb896b120Ea257B7950e40e363ef9e7EE2B7',
  [ChainId.POLYGON]: '0xB8Dbad148f205260eDF71Ba86FBa50b9619D8Eef',
  [ChainId.ETHEREUM]: '0x4638B34955c66E28C8261b23d38BC125d24a4E1B',
  [ChainId.LIF3CHAIN_TESTNET]: '0x10b490fd28E269F6b456324603488170008a0F6B',
  [ChainId.BSC]: '0xFB4d913C87e08Df47e35586c714F5afD4785539c',
}

export const default1InchRouterAddress = '0x111111125421ca6dc452d289314280a0f8842a65'

export const ROUTER_ADDRESSES_1INCH = {
  [ChainId.ARBITRUM]: default1InchRouterAddress,
  [ChainId.FANTOM]: default1InchRouterAddress,
  [ChainId.BASE]: default1InchRouterAddress,
  [ChainId.POLYGON]: default1InchRouterAddress,
  [ChainId.ETHEREUM]: default1InchRouterAddress,
  [ChainId.OPTIMISM]: default1InchRouterAddress,
  [ChainId.BSC]: default1InchRouterAddress,
}

export const OldTokenAddresses = {
  [ChainId.POLYGON]: {
    LIF3: '0x56ac3cb5e74b8a5a25ec9dc05155af1dad2715bd',
    LSHARE: '0xfb40b1efe90d4b786d2d9d9dc799b18bde92923b',
  },
}
