import { BigNumber } from '@ethersproject/bignumber'
import { useHistory } from 'react-router-dom'
import type { TransactionResponse } from '@ethersproject/providers'
import { Percent, Price } from '@uniswap/sdk-core'
import { Currency, CurrencyAmount, Ether, Token, WRAPPED_NATIVE_CURRENCY } from 'sdk/v3'
import { NonfungiblePositionManager, Pool, Position } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import Badge from 'components/Badge'
import { ButtonBordered, ButtonConfirmed, ButtonPrimary, ButtonPrimaryWhite } from 'components/Button'
import Card, { ComponentCard, LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import CurrencyLogo from 'components/CurrencyLogo'
import Row, { RowBetween, RowFixed } from 'components/Row'
import Toggle from 'components/Toggle'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useCurrency } from 'hooks/Tokens'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import { PoolState, usePool } from 'hooks/v3/usePools'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { Bound } from 'state/mint/v3/actions'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components'
import { HideSmall } from 'theme/components'
import RangeBadge from '../../components/Badge/RangeBadge'
import RateToggle from '../../components/RateToggle'
import { LoadingRows } from './styled'
import Loader from 'components/Loader'
import useIsTickAtLimit from 'hooks/v3/useIsTickAtLimit'
import { useCGTokenPrices } from 'hooks/coingecko/useCGTokenPrices'
import { useChainId } from 'hooks'
import { useV3PositionFees } from 'hooks/v3/useV3PositionFees'
import { unwrappedToken } from 'sdk/v3/utils'
import { NATIVE_CURRENCY } from 'sdk'
import { useV3PositionFromTokenId } from 'hooks/v3/useV3Positions'
import { Text } from 'rebass'
import { NumberType, formatNumber, formatTickPrice, formatToUSD } from 'utils/formatters'
import { getPriceOrderingFromPositionForUI } from './PositionList/PositionListItem'
import { usePositionTokenURI } from 'hooks/v3/usePositionTokenURI'
import { calculateGasMargin, getSigner, shortenAddress } from 'utils'
import { CheckCircle, ChevronLeft, Copy } from 'react-feather'
import { Box } from 'shared'
import { useMediaWith } from 'theme'
import { useNftOwner } from 'hooks/v3/useNftOwner'
import { Staking } from './Staking'
import { useIsPoolIncentivized } from 'hooks/v3/useIsPoolIncentivized'
import { usePositionByTokenId } from 'hooks/v3/usePositionByTokenId'
import { AccountPanelIconAction } from 'components/AccountPanel/styles'
import useCopyClipboard from 'hooks/useCopyClipboard'

const PositionPageButtonPrimary = styled(ButtonPrimary)`
  width: 228px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 12px;
`

const PageWrapper = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 840px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px 0;
  `};
`

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;
  width: auto;

  color: rgba(255, 255, 255, 0.5);
  font-size: 17px;
  font-weight: 400;
  text-decoration: none;
`

const PositionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: ${({ theme }) => theme.componentBg4};
  border-radius: 10px;
`

const PositionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: initial;
    gap: 25px;
    padding: 20px 10px 0;
  `};
`

const StyledBadge = styled(Badge)`
  padding: 0 8px;
  border-radius: 5px;
  background: ${({ theme }) => theme.componentBg4};
  min-height: 33px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 6px;
    min-height: 28px;
  `};
`

const BadgeText = styled.div`
  font-weight: 400;
  font-size: 15px;
  color: ${({ theme }) => theme.primaryText1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
  `};
`

const ExtentsText = styled.span`
  font-size: 13px;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 11px;
  `};
`

const StyledGridRow = styled.span`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  gap: 5px;
  align-items: center;
`

const ResponsiveRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%;
`};
`

const ActionButtonResponsiveRow = styled(ResponsiveRow)`
  width: 40%;
  justify-content: flex-end;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    justify-content: stretch;
  `};
`

const ResponsiveButtonConfirmed = styled(ButtonConfirmed)`
  border-radius: 5px;
  padding: 8px 14px;
  width: fit-content;
  font-size: 14px;
  font-weight: 700;
`
const PositionLabelRow = styled(RowFixed)`
  flex-wrap: wrap;
`

const NFTGrid = styled.div`
  display: grid;
  grid-template: 'overlap';
  min-height: 400px;
`

const NFTImage = styled.img`
  grid-area: overlap;
  height: 400px;
  /* Ensures SVG appears on top of canvas. */
  z-index: 1;
`

function CurrentPriceCard({
  inverted,
  pool,
  currencyQuote,
  currencyBase,
}: {
  inverted?: boolean
  pool?: Pool | null
  currencyQuote?: Currency
  currencyBase?: Currency
}) {
  const { upToSmall } = useMediaWith()

  if (!pool || !currencyQuote || !currencyBase) {
    return null
  }

  return (
    <ComponentCard padding={upToSmall ? '10px' : '20px 15px'}>
      <AutoColumn gap="10px" justify="center">
        <ExtentsText>Current price</ExtentsText>
        <Text fontSize="24px" textAlign="center">
          {inverted ? pool.token1Price.toSignificant(6) : pool.token0Price.toSignificant(6)}
        </Text>
        <ExtentsText>
          {currencyQuote?.symbol} per {currencyBase?.symbol}
        </ExtentsText>
      </AutoColumn>
    </ComponentCard>
  )
}

function LinkedCurrency({ chainId, currency }: { chainId?: number; currency?: Currency }) {
  const address = (currency as Token)?.address

  if (typeof chainId === 'number' && address) {
    return (
      <RowFixed gap="5px">
        <CurrencyLogo currency={currency} size="30px" />
        <Text fontSize="13px">{currency?.symbol}</Text>
      </RowFixed>
    )
  }

  return (
    <RowFixed gap="5px">
      <CurrencyLogo currency={currency} size="30px" />
      <Text fontSize="13px">{currency?.symbol}</Text>
    </RowFixed>
  )
}

function getRatio(
  lower: Price<Currency, Currency>,
  current: Price<Currency, Currency>,
  upper: Price<Currency, Currency>
) {
  try {
    if (!current.greaterThan(lower)) {
      return 100
    } else if (!current.lessThan(upper)) {
      return 0
    }

    const a = Number.parseFloat(lower.toSignificant(15))
    const b = Number.parseFloat(upper.toSignificant(15))
    const c = Number.parseFloat(current.toSignificant(15))

    const ratio = Math.floor((1 / ((Math.sqrt(a * b) - Math.sqrt(b * c)) / (c - Math.sqrt(b * c)) + 1)) * 100)

    if (ratio < 0 || ratio > 100) {
      throw Error('Out of range')
    }

    return ratio
  } catch {
    return undefined
  }
}

function NFT({ image }: { image: string; height: number }) {
  const imageRef = useRef<HTMLImageElement>(null)

  return (
    <NFTGrid>
      <NFTImage ref={imageRef} src={image} />
    </NFTGrid>
  )
}

const useInverter = ({
  priceLower,
  priceUpper,
  quote,
  base,
  invert,
}: {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
  invert?: boolean
}): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} => {
  return {
    priceUpper: invert ? priceLower?.invert() : priceUpper,
    priceLower: invert ? priceUpper?.invert() : priceLower,
    quote: invert ? base : quote,
    base: invert ? quote : base,
  }
}

export function PositionPageUnsupportedContent() {
  const chainId = useChainId()
  const chainIdOnlySearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId])

  return (
    <PageWrapper>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Text style={{ marginBottom: '8px' }}>Position unavailable</Text>
        <Text style={{ marginBottom: '32px' }}>
          To view a position, you must be connected to the network it belongs to.
        </Text>
        <PositionPageButtonPrimary
          as={Link}
          to={{ pathname: '/pools', search: chainIdOnlySearchParams.toString() }}
          width="fit-content"
        >
          Back to Pools
        </PositionPageButtonPrimary>
      </div>
    </PageWrapper>
  )
}

export default function PositionPage() {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const poolId = searchParams.get('pool') ?? ''

  return <PositionPageContent poolId={poolId} />
}

function parseTokenId(tokenId: string | undefined): BigNumber | undefined {
  if (!tokenId) return
  try {
    return BigNumber.from(tokenId)
  } catch (error) {
    return
  }
}

function PositionPageContent({ poolId }: { poolId: string }) {
  const { isIncentivized } = useIsPoolIncentivized(poolId)
  const chainId = useChainId()
  const { tokenId: tokenIdFromUrl } = useParams<{ tokenId?: string }>()
  const { position: subgraphPosition } = usePositionByTokenId({ tokenId: Number(tokenIdFromUrl) })
  const { account, library } = useWeb3React()
  const theme = useTheme()
  const { upToSmall, upToLarge } = useMediaWith()

  const parsedTokenId = parseTokenId(tokenIdFromUrl)
  const { loading, position: positionDetails } = useV3PositionFromTokenId(parsedTokenId)

  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
    tokenId,
  } = positionDetails || {}

  const removed = liquidity?.eq(0)

  const metadata = usePositionTokenURI(parsedTokenId)
  const [isCopied, setCopied] = useCopyClipboard()

  const currency0v2 = useCurrency(token0Address, 'V3')
  const currency1v2 = useCurrency(token1Address, 'V3')

  const currency0 =
    currency0v2?.symbol === NATIVE_CURRENCY[chainId].symbol
      ? Ether.onChain(chainId)
      : currency0v2 && new Token(chainId, token0Address, currency0v2.decimals, currency0v2.symbol, currency0v2.name)

  const currency1 =
    currency1v2?.symbol === NATIVE_CURRENCY[chainId].symbol
      ? Ether.onChain(chainId)
      : currency1v2 && new Token(chainId, token1Address, currency1v2.decimals, currency1v2.symbol, currency1v2.name)

  const token0 = currency0?.wrapped
  const token1 = currency1?.wrapped

  const prices = useCGTokenPrices({
    iniciator: 'liq-position',
    tokenSymbols: [
      (token0?.symbol ?? '') === WRAPPED_NATIVE_CURRENCY[chainId]?.symbol
        ? NATIVE_CURRENCY[chainId].symbol
        : token0?.symbol,
      (token1?.symbol ?? '') === WRAPPED_NATIVE_CURRENCY[chainId]?.symbol
        ? NATIVE_CURRENCY[chainId].symbol
        : token1?.symbol,
    ],
  })

  // flag for receiving WETH
  const [receiveWETH, setReceiveWETH] = useState(false)
  const nativeWrappedSymbol = WRAPPED_NATIVE_CURRENCY[chainId]?.symbol

  // construct Position from details returned
  const [poolState, pool] = usePool(token0 ?? undefined, token1 ?? undefined, feeAmount)
  const position = useMemo(() => {
    if (pool && liquidity && typeof tickLower === 'number' && typeof tickUpper === 'number') {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])

  const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

  const pricesFromPosition = getPriceOrderingFromPositionForUI(position)
  const [manuallyInverted, setManuallyInverted] = useState(false)

  // handle manual inversion
  const { priceLower, priceUpper, base } = useInverter({
    priceLower: pricesFromPosition.priceLower,
    priceUpper: pricesFromPosition.priceUpper,
    quote: pricesFromPosition.quote,
    base: pricesFromPosition.base,
    invert: manuallyInverted,
  })

  const inverted = token1 ? base?.symbol === token1.symbol : undefined
  const currencyQuote = inverted ? currency0 : currency1
  const currencyBase = inverted ? currency1 : currency0

  const ratio = useMemo(() => {
    return priceLower && pool && priceUpper
      ? getRatio(
          inverted ? priceUpper.invert() : priceLower,
          pool.token0Price,
          inverted ? priceLower.invert() : priceUpper
        )
      : undefined
  }, [inverted, pool, priceLower, priceUpper])

  // fees
  const [feeValue0, feeValue1] = useV3PositionFees(pool ?? undefined, positionDetails?.tokenId, receiveWETH)

  // these currencies will match the feeValue{0,1} currencies for the purposes of fee collection
  const currency0ForFeeCollectionPurposes = pool ? (receiveWETH ? pool.token0 : unwrappedToken(pool.token0)) : undefined
  const currency1ForFeeCollectionPurposes = pool ? (receiveWETH ? pool.token1 : unwrappedToken(pool.token1)) : undefined

  const [collecting, setCollecting] = useState<boolean>(false)
  const [collectMigrationHash, setCollectMigrationHash] = useState<string | null>(null)
  const isCollectPending = useIsTransactionPending(collectMigrationHash ?? undefined)
  const [showConfirm, setShowConfirm] = useState(false)

  const price0 = (prices.data?.[
    (token0?.symbol ?? '') === WRAPPED_NATIVE_CURRENCY[chainId]?.symbol
      ? NATIVE_CURRENCY[chainId].symbol
      : token0?.symbol
  ]?.usd || 0) as number
  const price1 = (prices.data?.[
    (token1?.symbol ?? '') === WRAPPED_NATIVE_CURRENCY[chainId]?.symbol
      ? NATIVE_CURRENCY[chainId].symbol
      : token1?.symbol
  ]?.usd || 0) as number

  const fiatValueOfFees = useMemo(() => {
    if (!price0 || !price1 || !feeValue0 || !feeValue1) return null

    // we wrap because it doesn't matter, the quote returns a USDC amount
    const feeValue0Wrapped = feeValue0?.wrapped
    const feeValue1Wrapped = feeValue1?.wrapped

    if (!feeValue0Wrapped || !feeValue1Wrapped) return null

    const amount0 = price0 * Number(feeValue0Wrapped?.toSignificant(6) ?? 0)
    const amount1 = price1 * Number(feeValue1Wrapped?.toSignificant(6) ?? 0)

    return amount0 + amount1
  }, [price0, price1, feeValue0, feeValue1])

  const fiatValueOfLiquidity = useMemo(() => {
    const amount0 = price0 * Number(position?.amount0.toSignificant(6) ?? 0)
    const amount1 = price1 * Number(position?.amount1.toSignificant(6) ?? 0)

    return amount0 + amount1
  }, [price0, price1, position])

  const addTransaction = useTransactionAdder()
  const positionManager = useV3NFTPositionManagerContract()
  const collect = useCallback(async () => {
    if (
      !currency0ForFeeCollectionPurposes ||
      !currency1ForFeeCollectionPurposes ||
      !chainId ||
      !positionManager ||
      !account ||
      !tokenId ||
      !library
    )
      return

    setCollecting(true)

    // we fall back to expecting 0 fees in case the fetch fails, which is safe in the
    // vast majority of cases
    const { calldata, value } = NonfungiblePositionManager.collectCallParameters({
      tokenId: tokenId.toString(),
      expectedCurrencyOwed0: feeValue0 ?? CurrencyAmount.fromRawAmount(currency0ForFeeCollectionPurposes, 0),
      expectedCurrencyOwed1: feeValue1 ?? CurrencyAmount.fromRawAmount(currency1ForFeeCollectionPurposes, 0),
      recipient: account,
    })

    const txn = {
      to: positionManager.address,
      data: calldata,
      value,
    }

    const connectedChainId = await getSigner(library, account).getChainId()
    if (chainId !== connectedChainId) throw new Error('Wrong chain')

    getSigner(library, account)
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate),
        }

        return getSigner(library, account)
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            setCollectMigrationHash(response.hash)
            setCollecting(false)
          })
      })
      .catch((error) => {
        setCollecting(false)
        console.error(error)
      })
  }, [
    chainId,
    feeValue0,
    feeValue1,
    currency0ForFeeCollectionPurposes,
    currency1ForFeeCollectionPurposes,
    positionManager,
    account,
    tokenId,
    addTransaction,
    library,
  ])

  const { data: owner } = useNftOwner(tokenId)

  const ownsNFT = owner === account

  const feeValueUpper = inverted ? feeValue0 : feeValue1
  const feeValueLower = inverted ? feeValue1 : feeValue0

  // check if price is within range
  const below = pool && typeof tickLower === 'number' ? pool.tickCurrent < tickLower : undefined
  const above = pool && typeof tickUpper === 'number' ? pool.tickCurrent >= tickUpper : undefined
  const inRange: boolean = typeof below === 'boolean' && typeof above === 'boolean' ? !below && !above : false

  function modalHeader() {
    return (
      <AutoColumn gap="16px">
        <LightCard padding="15px">
          <AutoColumn gap="10px">
            <RowBetween>
              <RowFixed gap="5px">
                <CurrencyLogo currency={feeValueUpper?.currency} size="30px" />
                <Text>{feeValueUpper ? formatNumber(Number(feeValueUpper.toSignificant(6)), 4) : '-'}</Text>
              </RowFixed>
              <Text>{feeValueUpper?.currency?.symbol}</Text>
            </RowBetween>
            <RowBetween>
              <RowFixed gap="5px">
                <CurrencyLogo currency={feeValueLower?.currency} size="30px" />
                <Text>{feeValueLower ? formatNumber(Number(feeValueLower.toSignificant(6)), 4) : '-'}</Text>
              </RowFixed>
              <Text>{feeValueLower?.currency?.symbol}</Text>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <Text fontSize={13} color={theme.accentText}>
          Collecting fees will withdraw currently available fees for you.
        </Text>
        <ButtonPrimaryWhite data-testid="modal-collect-fees-button" onClick={collect}>
          Collect
        </ButtonPrimaryWhite>
      </AutoColumn>
    )
  }

  const showCollectAsWeth = Boolean(
    ownsNFT &&
      (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0)) &&
      currency0 &&
      currency1 &&
      (currency0.isNative || currency1.isNative) &&
      !collectMigrationHash
  )

  if (!positionDetails && !loading) {
    return <PositionPageUnsupportedContent />
  }
  const chainIdOnlySearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId])

  const history = useHistory()

  const handleIncreaseClick = () => {
    const path = `/add?currencyIdA=${currency0?.isNative ? currency0?.symbol : currency0?.address}&currencyIdB=${
      currency1?.isNative ? currency1?.symbol : currency1?.address
    }`

    const stateData = {
      poolsId: poolId,
      tokenId: tokenId,
    }

    history.push(path, stateData)
  }

  return loading || poolState === PoolState.LOADING || !feeAmount ? (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  ) : (
    <>
      <PageWrapper>
        <TransactionConfirmationModal
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          attemptingTxn={collecting}
          hash={collectMigrationHash ?? ''}
          content={() => (
            <ConfirmationModalContent
              title={'Claim fees'}
              onDismiss={() => setShowConfirm(false)}
              bottomContent={modalHeader}
            />
          )}
          pendingText={'Collecting fees'}
        />
        <BackLink to={{ pathname: '/pools', search: chainIdOnlySearchParams.toString() }}>
          <ChevronLeft size={16} />
          Back to Pools
        </BackLink>
        <PositionBox>
          <PositionHeader>
            <PositionLabelRow gap={upToSmall ? '8px' : '15px'}>
              <DoubleCurrencyLogo currency0={currencyBase} currency1={currencyQuote} size={upToSmall ? 24 : 30} />
              <Text fontSize={upToSmall ? '16px' : '18px'} fontWeight={500}>
                {currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
              </Text>
              <StyledBadge>
                <BadgeText>{new Percent(feeAmount, 1_000_000).toSignificant()}%</BadgeText>
              </StyledBadge>
              <RangeBadge removed={removed} inRange={inRange} />
            </PositionLabelRow>
            {ownsNFT && (
              <ActionButtonResponsiveRow>
                {tokenId && !removed ? (
                  <ButtonBordered
                    as={Link}
                    to={`/remove/${tokenId}/${poolId}`}
                    padding="8px 14px"
                    style={{ fontSize: '13px', borderRadius: '5px', lineHeight: '15px' }}
                    width={upToSmall ? '100%' : 'fit-content'}
                  >
                    Remove liquidity
                  </ButtonBordered>
                ) : null}
                {currency0 && currency1 && feeAmount && tokenId ? (
                  <ButtonPrimaryWhite
                    onClick={handleIncreaseClick}
                    padding="8px 14px"
                    style={{ fontSize: '13px', borderRadius: '5px' }}
                    width={upToSmall ? '100%' : 'fit-content'}
                  >
                    <Text>Increase liquidity</Text>
                  </ButtonPrimaryWhite>
                ) : null}
              </ActionButtonResponsiveRow>
            )}
          </PositionHeader>
          <Box padding={upToSmall ? '0 10px 0' : '0 20px 0'}>
            <LightCard padding="15px">
              <RowBetween alignItems="center">
                <AutoColumn gap="4px">
                  <Text fontSize="13px" fontWeight={500}>
                    Pool Address
                  </Text>
                  <Row gap="10px">
                    <Text color={theme.primaryText1} fontSize="24px" fontWeight={500} overflow="hidden">
                      {upToSmall ? shortenAddress(poolId) : poolId}
                    </Text>
                    <AccountPanelIconAction as="button" onClick={() => setCopied(poolId)}>
                      {isCopied ? <CheckCircle /> : <Copy />}
                    </AccountPanelIconAction>
                  </Row>
                </AutoColumn>
              </RowBetween>
            </LightCard>
          </Box>

          <ResponsiveRow gap="25px" align="flex-start">
            <div
              style={{
                height: '100%',
                margin: upToSmall ? '0 auto' : 0,
              }}
            >
              {metadata.data ? (
                <Card
                  width="100%"
                  height="100%"
                  padding={upToSmall ? '0' : '0 0 0 20px'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    minWidth: '340px',
                  }}
                >
                  <NFT image={metadata.data.image} height={400} />
                </Card>
              ) : (
                <Card
                  width="100%"
                  height="100%"
                  style={{
                    minWidth: '340px',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <Loader />
                </Card>
              )}
            </div>
            <AutoColumn
              gap="25px"
              style={{ width: '100%', height: '100%', padding: upToSmall ? '0 10px' : '0 20px 0 0' }}
            >
              <LightCard padding="15px">
                <AutoColumn gap="4px">
                  <Text fontSize="13px" fontWeight={500}>
                    Liquidity
                  </Text>
                  {fiatValueOfLiquidity ? (
                    <Text fontSize={upToLarge ? '18px' : '24px'} fontWeight={500}>
                      {formatToUSD(fiatValueOfLiquidity, 2)}
                    </Text>
                  ) : (
                    <Text color={theme.primaryText1} fontSize={upToLarge ? '18px' : '24px'} fontWeight={500}>
                      $-
                    </Text>
                  )}
                </AutoColumn>
                <Box width="100%" height="1px" margin="15px 0" backgroundColor={theme.border2}></Box>
                <AutoColumn gap="15px">
                  <StyledGridRow>
                    <LinkedCurrency chainId={chainId} currency={currencyQuote} />
                    <Text fontSize="13px" color={theme.accentText} textAlign="right">
                      {inverted ? position?.amount0.toSignificant(4) : position?.amount1.toSignificant(4)}
                    </Text>
                    {typeof ratio === 'number' && !removed ? (
                      <Text fontSize="13px" color={theme.accentText} textAlign="right">
                        {inverted ? ratio : 100 - ratio}%
                      </Text>
                    ) : null}
                  </StyledGridRow>
                  <StyledGridRow>
                    <LinkedCurrency chainId={chainId} currency={currencyBase} />
                    <Text fontSize="13px" color={theme.accentText} textAlign="right">
                      {inverted ? position?.amount1.toSignificant(4) : position?.amount0.toSignificant(4)}
                    </Text>
                    {typeof ratio === 'number' && !removed ? (
                      <Text fontSize="13px" color={theme.accentText} textAlign="right">
                        {inverted ? 100 - ratio : ratio}%
                      </Text>
                    ) : null}
                  </StyledGridRow>
                </AutoColumn>
              </LightCard>
              <LightCard padding="15px">
                <ResponsiveRow alignItems="center">
                  <AutoColumn gap="4px">
                    <Text fontSize="13px" fontWeight={500}>
                      Unclaimed fees
                    </Text>
                    {fiatValueOfFees !== null ? (
                      <Text fontSize={upToLarge ? '18px' : '24px'} fontWeight={500}>
                        {formatToUSD(fiatValueOfFees, 2)}
                      </Text>
                    ) : (
                      <Text color={theme.primaryText1} fontSize={upToLarge ? '18px' : '24px'} fontWeight={500}>
                        $-
                      </Text>
                    )}
                  </AutoColumn>
                  {ownsNFT && (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) || !!collectMigrationHash) ? (
                    <ResponsiveButtonConfirmed
                      data-testid="collect-fees-button"
                      disabled={collecting || !!collectMigrationHash}
                      confirmed={!!collectMigrationHash && !isCollectPending}
                      onClick={() => setShowConfirm(true)}
                      style={{ width: upToSmall ? '100%' : 'fit-content' }}
                    >
                      {!!collectMigrationHash && !isCollectPending ? (
                        <Text color={theme.text1}>Collected</Text>
                      ) : isCollectPending || collecting ? (
                        <Text color={theme.text1}>...Collecting</Text>
                      ) : (
                        <>
                          <Text color={theme.text1}>Collect fees</Text>
                        </>
                      )}
                    </ResponsiveButtonConfirmed>
                  ) : null}
                </ResponsiveRow>
                <Box width="100%" height="1px" margin="15px 0" backgroundColor={theme.border2}></Box>
                <AutoColumn gap="15px">
                  <RowBetween>
                    <RowFixed gap="5px">
                      <CurrencyLogo currency={feeValueUpper?.currency} size="30px" />
                      <Text fontSize="13px">{feeValueUpper?.currency?.symbol}</Text>
                    </RowFixed>
                    <Text fontSize="13px" color={theme.accentText}>
                      {feeValueUpper ? formatNumber(Number(feeValueUpper.toSignificant(6)), 4) : '-'}
                    </Text>
                  </RowBetween>
                  <RowBetween>
                    <RowFixed gap="5px">
                      <CurrencyLogo currency={feeValueLower?.currency} size="30px" />
                      <Text fontSize="13px">{feeValueLower?.currency?.symbol}</Text>
                    </RowFixed>
                    <Text fontSize="13px" color={theme.accentText}>
                      {feeValueLower ? formatNumber(Number(feeValueLower.toSignificant(6)), 4) : '-'}
                    </Text>
                  </RowBetween>
                </AutoColumn>
                {showCollectAsWeth && (
                  <RowBetween gap="8px">
                    <Text fontSize="13px">Collect as {nativeWrappedSymbol}</Text>
                    <Toggle
                      id="receive-as-weth"
                      isActive={receiveWETH}
                      toggle={() => setReceiveWETH((receiveWETH) => !receiveWETH)}
                    />
                  </RowBetween>
                )}
              </LightCard>
            </AutoColumn>
          </ResponsiveRow>
          <Box padding={upToSmall ? '0 10px 0' : '0 20px 0'}>
            <LightCard padding="15px">
              <AutoColumn gap="20px">
                <RowBetween>
                  <Text fontSize="17px" fontWeight={500}>
                    Price range
                  </Text>
                  {currencyBase && currencyQuote && (
                    <RateToggle
                      currencyA={currencyBase}
                      currencyB={currencyQuote}
                      handleRateToggle={() => setManuallyInverted(!manuallyInverted)}
                    />
                  )}
                </RowBetween>

                <RowBetween gap={upToSmall ? '15px' : '20px'}>
                  <ComponentCard padding={upToSmall ? '10px' : '20px 15px'} width="100%" height="100%">
                    <AutoColumn gap="10px" justify="center">
                      <ExtentsText>Min price</ExtentsText>
                      <Text fontSize="24px" textAlign="center">
                        {formatTickPrice({
                          price: priceLower,
                          atLimit: tickAtLimit,
                          direction: Bound.LOWER,
                          numberType: NumberType.TokenTx,
                        })}
                      </Text>
                      <ExtentsText>
                        {' '}
                        {currencyQuote?.symbol} per {currencyBase?.symbol}
                      </ExtentsText>

                      <ExtentsText>Your position will be 100% {currencyBase?.symbol} at this price.</ExtentsText>
                    </AutoColumn>
                  </ComponentCard>
                  <ComponentCard padding={upToSmall ? '10px' : '20px 15px'} width="100%" height="100%">
                    <AutoColumn gap="10px" justify="center">
                      <ExtentsText>Max price</ExtentsText>
                      <Text fontSize="24px" textAlign="center">
                        {formatTickPrice({
                          price: priceUpper,
                          atLimit: tickAtLimit,
                          direction: Bound.UPPER,
                          numberType: NumberType.TokenTx,
                        })}
                      </Text>
                      <ExtentsText>
                        {' '}
                        {currencyQuote?.symbol} per {currencyBase?.symbol}
                      </ExtentsText>

                      <ExtentsText>Your position will be 100% {currencyQuote?.symbol} at this price.</ExtentsText>
                    </AutoColumn>
                  </ComponentCard>
                </RowBetween>
                <CurrentPriceCard
                  inverted={inverted}
                  pool={pool}
                  currencyQuote={currencyQuote}
                  currencyBase={currencyBase}
                />
              </AutoColumn>
            </LightCard>
          </Box>
          {(isIncentivized || subgraphPosition?.staked) && (
            <Staking tokenId={parsedTokenId?.toNumber() ?? 0} poolId={poolId} />
          )}
        </PositionBox>
      </PageWrapper>
    </>
  )
}
