import { ChainId, Currency, NATIVE_CURRENCY, Token, TokenAmount, WNATIVE } from 'sdk'
import { useMemo, useState } from 'react'
import { tryParseAmount } from '../state/swap/hooks'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useLPorCurrencyBalanceQuery } from '../state/wallet/hooks'
import { useChainId } from './index'
import { useWETHContract } from './useContract'
import { waitAndFinalizeTransaction } from 'utils/waitAndFinalizeTransaction'
import { useBalanceQuery } from './queries/useBalanceQuery'

export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP,
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE }
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined,
  typedValue: string | undefined,
  refetchBalances: () => Promise<void>
): { wrapType: WrapType; execute?: undefined | (() => Promise<void>); inputError?: string; isLoading?: boolean } {
  const chainId = useChainId()
  const wethContract = useWETHContract()
  const balanceQuery = useLPorCurrencyBalanceQuery(inputCurrency)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const balance = balanceQuery.data
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(() => tryParseAmount(typedValue, inputCurrency, chainId), [inputCurrency, typedValue])
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return NOT_APPLICABLE

    const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount)

    if (inputCurrency.symbol === NATIVE_CURRENCY[chainId].symbol && WNATIVE[chainId].symbol === outputCurrency.symbol) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  setIsLoading((prev) => (prev = true))
                  const txReceipt = await wethContract.deposit({ value: `0x${inputAmount.raw.toString(16)}` })
                  addTransaction(txReceipt, {
                    summary: `Wrap ${inputAmount.toSignificant(6)} ${NATIVE_CURRENCY[chainId].symbol} to ${
                      WNATIVE[chainId].symbol
                    }`,
                  })

                  await waitAndFinalizeTransaction(txReceipt.hash, chainId)
                  await refetchBalances()
                  setIsLoading((prev) => (prev = false))
                } catch (error) {
                  setIsLoading((prev) => (prev = false))
                  console.error('Could not deposit', error)
                }
              }
            : undefined,
        inputError:
          sufficientBalance || !typedValue ? undefined : `Insufficient ${NATIVE_CURRENCY[chainId].symbol} balance`,
        isLoading,
      }
    } else if (
      WNATIVE[chainId].symbol === inputCurrency.symbol &&
      outputCurrency.symbol === NATIVE_CURRENCY[chainId].symbol
    ) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  setIsLoading((prev) => (prev = true))
                  const txReceipt = await wethContract.withdraw(`0x${inputAmount.raw.toString(16)}`)
                  addTransaction(txReceipt, {
                    summary: `Unwrap ${inputAmount.toSignificant(6)} ${WNATIVE[chainId].symbol} to ${
                      NATIVE_CURRENCY[chainId].symbol
                    }`,
                  })
                  await waitAndFinalizeTransaction(txReceipt.hash, chainId)
                  await refetchBalances()
                  setIsLoading((prev) => (prev = false))
                } catch (error) {
                  setIsLoading((prev) => (prev = false))
                  console.error('Could not withdraw', error)
                }
              }
            : undefined,
        inputError: sufficientBalance || !typedValue ? undefined : `Insufficient ${WNATIVE[chainId].symbol} balance`,
        isLoading,
      }
    } else {
      return NOT_APPLICABLE
    }
  }, [wethContract, chainId, inputCurrency, outputCurrency, inputAmount, balance, addTransaction])
}
