import { useWeb3React } from '@web3-react/core'
import Big from 'big.js'
import { BigNumber } from 'ethers'
import { useChainId } from 'hooks'
import { useQuery } from 'react-query'
import { getContract, isAddress } from 'utils'
import { ERC20_ABI } from 'constants/abis/erc20'
import { PROVIDERS_BY_CHAIN } from 'connectors'
import { NATIVE_CURRENCY } from 'sdk'

export const useBalanceQuery = (address?: string) => {
  const { account, library } = useWeb3React()
  const chainId = useChainId()

  return useQuery(
    ['get-token-balance', address, account, chainId],
    async () => {
      if (!address || address === NATIVE_CURRENCY[chainId].symbol) {
        const balance = await library?.getBalance(account ?? '', 'pending')

        return Big(balance.toString())
      }

      const contract = getContract(address, ERC20_ABI, PROVIDERS_BY_CHAIN[chainId])

      const balance = (await contract?.balanceOf(account)) as BigNumber

      return Big(balance.toString())
    },
    { enabled: Boolean(account), refetchOnMount: false, refetchInterval: 1000, refetchOnWindowFocus: false }
  )
}
