import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import Numeral from 'numeral'
import { Area, XAxis, YAxis, ResponsiveContainer, AreaChart, Tooltip, ReferenceLine } from 'recharts'

import useTheme from 'hooks/useTheme'
import { formatToUSD } from 'utils/formatters'
import { TimeframeOption, timeframeOptions, useCGPrices } from './hooks/useCGPrices'
import { Box } from 'shared'
import Row, { AutoRow } from 'components/Row'
import { DateRow, PriceRow, TimeframeButton } from './styles'
import { Text } from 'rebass'
import { useMediaWith } from 'theme'
import { calculatePercentageDifference } from 'utils/calculatePercentageDifference'
import { LoadingDotsIcon } from 'ui/LoadingDotsIcon'

import { TokenPriceInfo } from '../TokenPriceInfo'
import { useSelectedCurrencies } from 'state/swap/hooks'
import { useCGMarketsData } from 'hooks/coingecko/useCGMarketsData'

export const toNiceDate = (date, timeframe: TimeframeOption) => {
  const formatting = {
    [timeframeOptions['1H']]: 'hh:mm A',
    [timeframeOptions['4H']]: 'hh:mm A',
    [timeframeOptions['1D']]: 'hh:mm A',
    [timeframeOptions['1W']]: 'MMM DD',
    [timeframeOptions['1M']]: 'MMM DD',
  }

  const result = dayjs(date).format(formatting[timeframe])

  return result
}

export const toK = (num) => {
  return Numeral(num).format('0.[00]a')
}

const percentageFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 })
const numberFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 4 })

export const toNiceDateYear = (date) => dayjs(date).format('MMMM DD, YYYY')

type Props = {
  isTokenChart?: boolean
}

export const SimpleRechart: React.FC<Props> = ({ isTokenChart }) => {
  const { upToMedium } = useMediaWith()
  const theme = useTheme()
  const { inputCurrency, outputCurrency } = useSelectedCurrencies()

  const [selectedTimeframe, setTimeframe] = useState<TimeframeOption>(timeframeOptions['1M'])
  const [isHovering, setIsHovering] = useState(false)

  const [hoveredPrice, setHoveredPrice] = useState()

  const handleHover = (data) => {
    if (data?.activePayload?.[0]?.value) {
      setHoveredPrice(data.activePayload[0].value)
    }
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
    setHoveredPrice(undefined)
  }

  useEffect(() => {
    if (!isHovering) {
      setHoveredPrice(undefined)
    }
  }, [isHovering])

  const getCurrency = () => {
    const isInputStable =
      inputCurrency?.symbol === 'USDC' ||
      inputCurrency?.symbol === 'USDT' ||
      inputCurrency?.symbol === 'BUSD' ||
      inputCurrency?.symbol === 'USDC (Multichain)'

    const isOutputStable =
      outputCurrency?.symbol === 'USDC' ||
      outputCurrency?.symbol === 'USDT' ||
      outputCurrency?.symbol === 'BUSD' ||
      outputCurrency?.symbol === 'USDC (Multichain)'

    if (!inputCurrency || !outputCurrency) return

    return outputCurrency
  }

  const currency = getCurrency()

  const tokensListQuery = useCGMarketsData()
  const coinMarketData = tokensListQuery.data?.find((coin) => coin.symbol === currency?.symbol?.toLowerCase())

  const pricesQuery = useCGPrices(selectedTimeframe, coinMarketData?.id)

  const firstPrice = pricesQuery.data?.[0]?.price
  const currentPrice = pricesQuery.data?.[pricesQuery.data.length - 1]?.price

  const price = hoveredPrice ?? currentPrice

  const percentageDiffHovered =
    hoveredPrice && currentPrice ? calculatePercentageDifference(hoveredPrice, currentPrice) : null

  const priceDiffHovered = currentPrice && hoveredPrice ? hoveredPrice - currentPrice : null

  const priceDiff = firstPrice && currentPrice ? currentPrice - firstPrice : null

  const percentageDiff = firstPrice && currentPrice ? calculatePercentageDifference(currentPrice, firstPrice) : null

  const renderChart = () => {
    if (pricesQuery.isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          top="0"
          left="0"
          right="0"
          bottom="0"
          width="100%"
          height="100%"
          position="absolute"
        >
          <Box width="40px" height="40px">
            <LoadingDotsIcon size={40} color={theme.primary1} />
          </Box>
        </Box>
      )
    }

    if (!pricesQuery.data) {
      return 'Chart is unavailable'
    }

    return (
      <ResponsiveContainer>
        <AreaChart
          onMouseMove={handleHover}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={handleMouseLeave}
          margin={{ top: 0, right: 10, bottom: 6, left: 0 }}
          barCategoryGap={1}
          data={pricesQuery.data ?? []}
        >
          <>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="15%" stopColor={'rgba(204, 177, 117, 0.8)'} stopOpacity={1} />
                <stop
                  offset="99%"
                  stopColor={theme.darkMode ? 'rgba(255, 255, 255, 0.24)' : 'rgba(156, 166, 255, 0.24)'}
                  stopOpacity={1}
                />
              </linearGradient>
            </defs>
            <XAxis
              tickLine={false}
              axisLine={false}
              interval="preserveEnd"
              tickMargin={7}
              minTickGap={40}
              tickFormatter={(tick) => toNiceDate(tick, selectedTimeframe)}
              dataKey="date"
              tick={{ fill: theme.white }}
              domain={['dataMin', 'dataMax']}
              fontSize={'13px'}
              fontWeight={500}
            />
            <YAxis
              type="number"
              orientation="left"
              tickFormatter={(tick) => '$' + toK(tick)}
              axisLine={false}
              tickLine={false}
              width={75}
              interval="preserveEnd"
              minTickGap={80}
              yAxisId={0}
              tickMargin={5}
              tick={{ fill: theme.white }}
              domain={['dataMin', 'dataMax']}
              fontSize={'13px'}
              fontWeight={500}
            />
          </>

          <Tooltip content={() => null} />
          <ReferenceLine y={hoveredPrice} stroke="#ccc" />
          <Area
            strokeWidth={isTokenChart ? 2 : 1}
            dot={false}
            type="monotone"
            name={' (USD)'}
            dataKey={'price'}
            yAxisId={0}
            stroke={theme.primary1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    )
  }

  const getDiffColor = () => {
    if (!currentPrice) {
      return undefined
    }

    if (hoveredPrice && percentageDiffHovered) {
      return percentageDiffHovered < 0 ? theme.red1 : theme.green1
    }

    if (currentPrice && percentageDiff) {
      return percentageDiff < 0 ? theme.red1 : theme.green1
    }

    return undefined
  }

  const renderDiff = () => {
    if (hoveredPrice) {
      return percentageDiffHovered !== null && priceDiffHovered !== null
        ? `${numberFormatter.format(priceDiffHovered)} (${percentageFormatter.format(percentageDiffHovered)}%)`
        : '--'
    }

    return percentageDiff !== null && priceDiff !== null
      ? `${numberFormatter.format(priceDiff)} (${percentageFormatter.format(percentageDiff)}%)`
      : '--'
  }

  return (
    <>
      {!isTokenChart && (
        <PriceRow>
          <Row width="auto" gap="13px">
            <Text fontSize={26}>{price ? formatToUSD(price, price < 0.1 ? 4 : 2) : '--'}</Text>
            <Text fontSize={15} color={getDiffColor()}>
              {renderDiff()}
            </Text>
          </Row>

          <DateRow>
            <TimeframeButton
              active={selectedTimeframe === timeframeOptions['1H']}
              onClick={() => setTimeframe(timeframeOptions['1H'])}
            >
              1H
            </TimeframeButton>
            <TimeframeButton
              active={selectedTimeframe === timeframeOptions['4H']}
              onClick={() => setTimeframe(timeframeOptions['4H'])}
            >
              4H
            </TimeframeButton>
            <TimeframeButton
              active={selectedTimeframe === timeframeOptions['1D']}
              onClick={() => setTimeframe(timeframeOptions['1D'])}
            >
              1D
            </TimeframeButton>
            <TimeframeButton
              active={selectedTimeframe === timeframeOptions['1W']}
              onClick={() => setTimeframe(timeframeOptions['1W'])}
            >
              1W
            </TimeframeButton>
            <TimeframeButton
              active={selectedTimeframe === timeframeOptions['1M']}
              onClick={() => setTimeframe(timeframeOptions['1M'])}
            >
              1M
            </TimeframeButton>
          </DateRow>
        </PriceRow>
      )}
      {isTokenChart && (
        <Box margin="10px 0 20px" display="flex" justifyContent="space-between">
          <TokenPriceInfo
            currency={getCurrency()}
            price={price ? formatToUSD(price, price < 0.1 ? 4 : 2) : '--'}
            diffPrice={renderDiff()}
            diffColor={getDiffColor()}
          />
          <DateRow>
            <TimeframeButton
              active={selectedTimeframe === timeframeOptions['1H']}
              onClick={() => setTimeframe(timeframeOptions['1H'])}
            >
              1H
            </TimeframeButton>
            <TimeframeButton
              active={selectedTimeframe === timeframeOptions['4H']}
              onClick={() => setTimeframe(timeframeOptions['4H'])}
            >
              4H
            </TimeframeButton>
            <TimeframeButton
              active={selectedTimeframe === timeframeOptions['1D']}
              onClick={() => setTimeframe(timeframeOptions['1D'])}
            >
              1D
            </TimeframeButton>
            <TimeframeButton
              active={selectedTimeframe === timeframeOptions['1W']}
              onClick={() => setTimeframe(timeframeOptions['1W'])}
            >
              1W
            </TimeframeButton>
            <TimeframeButton
              active={selectedTimeframe === timeframeOptions['1M']}
              onClick={() => setTimeframe(timeframeOptions['1M'])}
            >
              1M
            </TimeframeButton>
          </DateRow>
        </Box>
      )}

      <Box
        height={upToMedium ? 'calc(100% - 160px)' : 'calc(100% - 115px)'}
        minHeight={upToMedium ? 'auto' : isTokenChart ? '300px' : '750px'}
        position="relative"
      >
        <div style={{ position: 'absolute', inset: 0, left: '-30px' }}>{renderChart()}</div>
      </Box>

      {isTokenChart && (
        <DateRow isTokenChart={isTokenChart}>
          <TimeframeButton
            active={selectedTimeframe === timeframeOptions['1H']}
            onClick={() => setTimeframe(timeframeOptions['1H'])}
            isTokenChart={isTokenChart}
          >
            1H
          </TimeframeButton>
          <TimeframeButton
            active={selectedTimeframe === timeframeOptions['4H']}
            onClick={() => setTimeframe(timeframeOptions['4H'])}
            isTokenChart={isTokenChart}
          >
            4H
          </TimeframeButton>
          <TimeframeButton
            active={selectedTimeframe === timeframeOptions['1D']}
            onClick={() => setTimeframe(timeframeOptions['1D'])}
            isTokenChart={isTokenChart}
          >
            1D
          </TimeframeButton>
          <TimeframeButton
            active={selectedTimeframe === timeframeOptions['1W']}
            onClick={() => setTimeframe(timeframeOptions['1W'])}
            isTokenChart={isTokenChart}
          >
            1W
          </TimeframeButton>
          <TimeframeButton
            active={selectedTimeframe === timeframeOptions['1M']}
            onClick={() => setTimeframe(timeframeOptions['1M'])}
            isTokenChart={isTokenChart}
          >
            1M
          </TimeframeButton>
        </DateRow>
      )}
    </>
  )
}
