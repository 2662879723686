import React from 'react'

export const MediumIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2873 11.9881C13.2873 15.4009 10.5395 18.1674 7.15011 18.1674C3.76071 18.1674 1.0127 15.4009 1.0127 11.9881C1.0127 8.57537 3.7605 5.80859 7.15011 5.80859C10.5397 5.80859 13.2873 8.57537 13.2873 11.9881Z"
        fill="currentColor"
      />
      <path
        d="M20.0197 11.988C20.0197 15.2004 18.6458 17.8055 16.951 17.8055C15.2562 17.8055 13.8823 15.2004 13.8823 11.988C13.8823 8.77562 15.256 6.17044 16.9508 6.17044C18.6456 6.17044 20.0195 8.77478 20.0195 11.988"
        fill="currentColor"
      />
      <path
        d="M22.7736 11.988C22.7736 14.8655 22.2904 17.1996 21.6943 17.1996C21.0982 17.1996 20.6152 14.8661 20.6152 11.988C20.6152 9.10985 21.0984 6.77637 21.6943 6.77637C22.2902 6.77637 22.7736 9.10964 22.7736 11.988Z"
        fill="currentColor"
      />
    </svg>
  )
}
