import styled from 'styled-components'

export const ChainToggleBtn = styled.a<{ bg?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  min-width: 152px;
  min-height: 44px;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  font: inherit;
  color: ${({ theme }) => theme.primaryText1};
  cursor: pointer;

  @media screen and (max-width: 1510px) {
    min-width: 72px;
  }
`

export const ChainToggleItem = styled(ChainToggleBtn)<{ bg?: string; selected?: boolean }>`
  padding: 10px 20px;
  background: transparent;
  box-shadow: none;
  min-width: auto;
  justify-content: flex-start;
  transition: opacity 0.3s, background-color 0.3s;
  text-decoration: none;
  min-height: auto;
  background: ${({ selected, theme }) => selected && theme.appBg};

  &:hover {
    background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  }
`

export const ChainIcon = styled.img`
  max-width: 26px;
  max-height: 22px;
  min-width: 14px;
`

export const ChainDropDown = styled.span`
  width: calc(100% + 30px);
  min-width: 180px;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: calc(100% + 20px);
  left: -15px;
  z-index: 100;
  background: ${({ theme }) => theme.componentBg2};
  box-shadow: ${({ theme }) => theme.contrastShadow};
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: auto;
    padding: 6px 0;
    left: -5px;
    align-items: center;
    width: calc(100% + 10px);
  `}
`

export const ChainBtn = styled.a<{ bg?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  min-width: 152px;
  min-height: 44px;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  border-radius: 22px;
  font: inherit;
  color: ${({ theme }) => theme.primaryText1};
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    min-width: auto;
  `}
`

export const ChainItem = styled(ChainBtn)<{ bg?: string }>`
  padding: 15px 20px;
  background: transparent;
  box-shadow: none;
  min-width: auto;
  justify-content: flex-start;
  transition: opacity 0.3s, background-color 0.3s;
  text-decoration: none;
  min-height: auto;
  border-radius: 30px;

  &:hover {
    background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    background: ${({ bg }: any) => (bg ? bg : 'transparent')};
  `}
`
