import { Currency, CurrencyAmount, NATIVE_CURRENCY, Token } from 'sdk'
import React, { CSSProperties } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useActiveWeb3React, useChainId } from 'hooks'
import { TYPE } from 'theme'
import Column from 'components/Column'
import CurrencyLogo from '../../CurrencyLogo'
import { MenuItem } from '../styleds'
import Loader from 'components/Loader'
import { Box } from 'shared'
import { TokenLevel } from '../../TokenLevel'
import { addTokenToWallet } from 'utils/wallet'
import MetamaskIcon from 'assets/images/metamask.png'

import { ReactComponent as ExternalLinkSvg } from 'assets/svg/external-link.svg'

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`

const StyledSiteIcon = styled(ExternalLinkSvg)`
  stroke-opacity: 0.3;
  transition: all 0.3s;
  &:hover {
    stroke: #fff;
    stroke-opacity: 1;
  }
`

const STLink = styled.a`
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div`
  cursor: pointer;
`

const MetamaskIconImg = styled.img`
  width: 20px;
  height: 20px;
`

function Balance({ balance }: { balance: CurrencyAmount }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
}

export const TokenRow: React.FC<{
  currency: Token
  onSelect: () => void
  isSelected: boolean
  otherSelected: boolean
  style: CSSProperties
  balance: CurrencyAmount | undefined
  account: string | undefined
}> = ({ currency, onSelect, isSelected, otherSelected, style, balance, account }) => {
  const chainId = useChainId()

  return (
    <MenuItem
      style={style}
      onClick={() => (isSelected || otherSelected ? null : onSelect())}
      disabled={isSelected || otherSelected}
      selected={otherSelected}
    >
      <Box mr="10px">
        <CurrencyLogo currency={currency} size={'24px'} />
      </Box>
      <Column>
        <Text title={currency.name} fontWeight={500}>
          {currency.symbol}
        </Text>
        <TYPE.darkGray ml="0px" fontSize={'12px'} fontWeight={300}>
          {currency.name}
        </TYPE.darkGray>
      </Column>
      <Box alignItems="center" ml="auto" display="flex">
        <Box mr={10}>{balance ? <Balance balance={balance} /> : account ? <Loader /> : null}</Box>
        <Box ml={10} width={18} height={18}>
          {currency?.website && (
            <STLink href={currency.website} onClick={(e) => e.stopPropagation()} target="_blank" rel="noreferrer">
              <StyledSiteIcon />
            </STLink>
          )}
        </Box>
        <Box ml={10} width={18} height={18}>
          {currency !== NATIVE_CURRENCY[chainId] && currency !== Currency.USD_CARD ? (
            <IconWrapper
              onClick={(e) => {
                e.stopPropagation()
                addTokenToWallet(currency as any)
              }}
            >
              <MetamaskIconImg src={MetamaskIcon} />
            </IconWrapper>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </MenuItem>
  )
}
