import React, { useRef, useState } from 'react'
import { ChainToggleItem } from './styles'
import { Text } from 'rebass'
import { ChainId } from 'sdk'
import { languages } from './constants'
import Column, { AutoColumn } from 'components/Column'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

type Props = {
  selectedChainId: ChainId
  setSelectedChainId: (value: ChainId) => void
}

export const PaddedColumn = styled(AutoColumn)`
  padding: 20px;
  background-color: ${({ theme }) => theme.componentBg2};
`

export const ContentColumn = styled(AutoColumn)`
  padding: 20px;
`

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  // useOnClickOutside(menuRef, menuOpen ? () => setMenuOpen(!menuOpen) : undefined)

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  const selectedLanguage = languages.find((lang) => lang.name === i18n.language)

  return (
    <>
      {languages.map((language) => {
        return (
          <ChainToggleItem
            onClick={() => changeLanguage(language.name)}
            key={language.name}
            selected={language.label === selectedLanguage?.label}
          >
            {language.logo && <img src={language.logo} alt={language.name} height={11} width={14} />}
            <Text fontSize="14px" lineHeight="18px">
              {language.label}
            </Text>
          </ChainToggleItem>
        )
      })}
    </>
  )
}
