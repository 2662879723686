import React, { useState } from 'react'
import { Box, MoonIcon, SunIcon } from 'shared'
import esLangIcon from 'assets/images/es-lang.svg'
import {
  GlobalPanelAction,
  GlobalPanelArrow,
  GlobalPanelBox,
  GlobalPanelHr,
  GlobalPanelLanaugeTitle,
  GlobalPanelLangIcon,
  GlobalPanelRow,
  GlobalPanelText,
  BackButton,
  LanguageHeadericon,
} from './styles'
import { useDarkModeManager } from 'state/user/hooks'
import { useTranslation } from 'react-i18next'
import { LanguageSwitcher } from 'components/Header/components/LanguageSwitcher'
import { languages } from 'components/Header/components/LanguageSwitcher/constants'
import { Text } from 'rebass'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const GlobalSettingsPanel = ({ isOpen, setIsOpen }: Props) => {
  const [darkMode, toggleDarkMode] = useDarkModeManager()
  const [languageOpen, setLanguageOpen] = useState(false)
  const { i18n } = useTranslation()

  const handleLanguage = () => {
    setLanguageOpen(!languageOpen)
  }

  const selectedLanguage = languages.find((lang) => lang.name === i18n.language)

  return (
    <GlobalPanelBox>
      {languageOpen ? (
        <>
          <GlobalPanelRow>
            <BackButton
              onClick={() => {
                handleLanguage()
              }}
            >
              <LanguageHeadericon />
            </BackButton>
            <GlobalPanelLanaugeTitle>Language</GlobalPanelLanaugeTitle>
            <Box width="10px" height="10px"></Box>
          </GlobalPanelRow>
          <GlobalPanelHr />
          <Box>
            <LanguageSwitcher />
          </Box>
        </>
      ) : (
        <>
          <Text fontSize="13px" padding="7px 10px" mb="3px">
            Preferences
          </Text>
          <GlobalPanelAction as="button" onClick={handleLanguage}>
            <GlobalPanelText>Language</GlobalPanelText>
            <Box display="flex" alignItems="center">
              <GlobalPanelLangIcon src={selectedLanguage?.logo ? selectedLanguage?.logo : esLangIcon} alt="Language" />
              <GlobalPanelArrow />
            </Box>
          </GlobalPanelAction>
          <GlobalPanelAction as="button" onClick={toggleDarkMode}>
            <GlobalPanelText>{darkMode ? 'Change to Light Theme' : 'Change to Dark Theme'}</GlobalPanelText>
            <Box width="16px" height="16px">
              {darkMode ? <MoonIcon /> : <SunIcon />}
            </Box>
          </GlobalPanelAction>
        </>
      )}
    </GlobalPanelBox>
  )
}
