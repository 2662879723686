import { Box } from 'shared'
import React from 'react'
import styled from 'styled-components'
import { useMediaWith } from 'theme'

const ToggleElement = styled.span<{ isActive?: boolean; isOnSwitch?: boolean }>`
  width: 22px;
  height: 22px;
  border-radius: 100px;
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: ${({ theme }) => theme.contrastElementShadow};
  transform: translateX(${({ isActive }) => (isActive ? '0px' : '26px')});
  transition: transform 0.1s;
  will-change: transform;

  ${({ theme, isActive }) => theme.mediaWidth.upToSmall`
    transform: translateX(${isActive ? '0px' : '18px'});
  `}
`

const StyledToggle = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
  border-radius: 20px;
  background: ${({ theme, isActive }) => (isActive ? theme.toggleBackground : 'transparent')};
  border: ${({ theme, isActive }) => (isActive ? 'none' : `1px solid ${theme.white}`)};
  cursor: pointer;
  outline: none;
  padding: 2px;
`

export interface ToggleProps {
  isActive: boolean
  toggle: () => void
  disabled?: boolean
}

export default function Toggle({ isActive, toggle, disabled }: ToggleProps) {
  const { upToSmall } = useMediaWith()
  return (
    <StyledToggle type="button" disabled={disabled} isActive={isActive} onClick={toggle}>
      <Box height="22px" width={upToSmall ? '40px' : '48px'} position="relative">
        <ToggleElement isActive={!isActive} />
      </Box>
    </StyledToggle>
  )
}
