import invariant from 'tiny-invariant'
import { validateAndParseAddress } from '../utils'
import { Currency } from './currency'
import stlLIF3Logo from 'assets/images/logos/stllife.svg'
import stlTOMBLogo from 'assets/images/logos/stltomb.svg'
import stlUSDCLogo from 'assets/images/logos/stlusdc.svg'
import stlL3USDLogo from 'assets/images/logos/stll3usd.svg'
import FANTOMLogo from 'assets/images/logos/fantom.svg'
import { ChainId } from 'sdk/constants'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
type TokenInfo = {
  address: string
  decimals: number
  symbol?: string
  name?: string
  logo?: string
  level?: number
  website?: string
  slippage?: number
  socials?: Record<string, string>
}
export class Token extends Currency {
  public readonly address: string
  public readonly logo?: string
  public readonly level?: number
  public readonly website?: string
  public readonly slippage?: number
  public readonly socials?: Record<string, string>

  public constructor(tokenInfo: TokenInfo) {
    super(tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name)
    this.address = validateAndParseAddress(tokenInfo.address)
    this.logo = tokenInfo.logo
    this.level = tokenInfo.level
    this.website = tokenInfo.website
    this.slippage = tokenInfo.slippage
    this.socials = tokenInfo.socials
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    // invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }

  public get logoURI(): string | undefined {
    return this.logo
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export const WNATIVE = {
  [ChainId.FANTOM]: new Token({
    address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    decimals: 18,
    symbol: 'WFTM',
    name: 'Wrapped Fantom',
    logo: 'https://assets.lif3.com/swap/tokens/WFTM.svg',
  }),
  [ChainId.BSC]: new Token({
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    decimals: 18,
    symbol: 'WBNB',
    name: 'Wrapped BNB',
    logo: 'https://assets.lif3.com/swap/tokens/BNB.svg',
  }),
  [ChainId.AMOY_TESTNET]: new Token({
    address: '0x40FE2F4EeaD3d472E5c064b8e970e5a595A44558',
    decimals: 18,
    symbol: 'WMATIC',
    name: 'Wrapped Matic',
    logo: 'https://assets.lif3.com/swap/tokens/MATIC.svg',
  }),
  [ChainId.POLYGON]: new Token({
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    decimals: 18,
    symbol: 'WMATIC',
    name: 'Wrapped Matic',
    logo: 'https://assets.lif3.com/swap/tokens/MATIC.svg',
  }),
  [ChainId.ETHEREUM]: new Token({
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    decimals: 18,
    symbol: 'WETH',
    name: 'Wrapped Ethereum',
    logo: 'https://assets.lif3.com/swap/tokens/WETH.svg',
  }),
  [ChainId.OPTIMISM]: new Token({
    address: '0x4200000000000000000000000000000000000006',
    decimals: 18,
    symbol: 'WETH',
    name: 'Wrapped Ethereum',
    logo: 'https://assets.lif3.com/swap/tokens/WETH.svg',
  }),
  [ChainId.ARBITRUM]: new Token({
    address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    decimals: 18,
    symbol: 'WETH',
    name: 'Wrapped Ethereum',
    logo: 'https://assets.lif3.com/swap/tokens/WETH.svg',
  }),
  [ChainId.BASE]: new Token({
    address: '0x4200000000000000000000000000000000000006',
    decimals: 18,
    symbol: 'WETH',
    name: 'Wrapped Ethereum',
    logo: 'https://assets.lif3.com/swap/tokens/WETH.svg',
  }),
  [ChainId.LIF3CHAIN_TESTNET]: new Token({
    address: '0x2C2D8a078B33bf7782a16AcCE2C5BA6653a90D5f',
    decimals: 18,
    symbol: 'WLIF3',
    name: 'Wrapped Lif3',
    logo: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
  }),
  [ChainId.LIF3CHAIN]: new Token({
    address: '0xDEa12C8C23994EA2D88eD99eE1bdc0FF56f7F9d1',
    decimals: 18,
    symbol: 'WLIF3',
    name: 'Wrapped Lif3',
    logo: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
  }),
}

//support for curve pools in Cemetery v2
export const CURVEPOOL1 = new Token({
  address: '0x20eD8cAa61BA7147a2545dc7f09e241329799d6E',
  decimals: 18,
  symbol: 'L3USD2POOL3CRV-f',
  name: 'Curve.fi Factory USD Metapool: L3USD - 2pool',
})

export const CURVEPOOL2 = new Token({
  address: '0x6E6c999e1893dc2E68b4193108bEC307b6C42f96',
  decimals: 18,
  symbol: 'L3USD3POOL-f',
  name: 'Curve.fi Factory Plain Pool: L3USD3Pool',
})

export const stlTOMB = new Token({
  address: '0x63C269dc9f99e310cf3a7c781215Bfe4082F4316',
  decimals: 18,
  symbol: 'stlTOMB',
  name: 'stlTOMB',
  logo: stlTOMBLogo,
})

export const stlFTM = new Token({
  address: '0xDEa12C8C23994EA2D88eD99eE1bdc0FF56f7F9d1',
  decimals: 18,
  symbol: 'stlFTM',
  name: 'stlFTM',
  logo: 'https://assets.lif3.com/swap/tokens/STLFTM.svg',
})

export const stlLIFE = new Token({
  address: '0x8FfdA8876B7CF809d97906FD5eaE41A5A47A6607',
  decimals: 18,
  symbol: 'stlLIF3',
  name: 'stlLIF3',
  logo: 'https://assets.lif3.com/swap/tokens/STLLIF3.svg',
})

export const stlUSDC = new Token({
  address: '0x97733069C75128384f28f1aD59E7b42A10604ac7',
  decimals: 6,
  symbol: 'stlUSDC',
  name: 'stlUSDC',
  logo: 'https://assets.lif3.com/swap/tokens/STLUSDC.svg',
})

export const stlL3USD = new Token({
  address: '0xf50cCefe851874f6F9822E11a94A6098f80518B6',
  decimals: 18,
  symbol: 'stlL3USD',
  name: 'stlL3USD',
  logo: 'https://assets.lif3.com/swap/tokens/STLL3USD.svg',
})

export const stlTokensList = [stlTOMB, stlFTM, stlLIFE, stlUSDC, stlL3USD]
