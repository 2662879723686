import { TokensConfigItem } from 'types'
import { ChainId } from 'sdk'

export const swapTokensList = {
  [ChainId.FANTOM]: [
    {
      address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
      chainId: 250,
      name: 'WFTM',
      symbol: 'WFTM',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WFTM.svg',
    },
    {
      address: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
      chainId: 250,
      name: 'TOMB',
      symbol: 'TOMB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TOMB.svg',
    },
    {
      address: '0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
      chainId: 250,
      name: 'TSHARE',
      symbol: 'TSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TSHARE.svg',
    },
    {
      address: '0x24248CD1747348bDC971a5395f4b3cd7feE94ea0',
      chainId: 250,
      name: 'TBOND',
      symbol: 'TBOND',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TBOND.svg',
    },
    {
      address: '0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
      chainId: 250,
      name: 'MAI',
      symbol: 'MAI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/MAI.svg',
    },
    {
      address: '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
      chainId: 250,
      name: 'BASED',
      symbol: 'BASED',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BASED.svg',
    },
    {
      address: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
      chainId: 250,
      name: 'Wrapped Bitcoin',
      symbol: 'WBTC',
      decimals: 8,
      logoURI: 'https://assets.lif3.com/swap/tokens/BTC.svg',
    },
    {
      address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
      chainId: 250,
      name: 'Wrapped Ethereum',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    },
    {
      address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
      chainId: 250,
      name: 'USDC',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
      chainId: 250,
      name: 'fUSDT',
      symbol: 'fUSDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0x82f0B8B456c1A451378467398982d4834b6829c1',
      chainId: 250,
      name: 'MIM',
      symbol: 'MIM',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/MIM.svg',
    },
    {
      address: '0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
      chainId: 250,
      name: 'FUSD',
      symbol: 'FUSD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/FUSD.svg',
    },
    {
      address: '0x49C290Ff692149A4E16611c694fdED42C954ab7a',
      chainId: 250,
      name: 'BSHARE',
      symbol: 'BSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BSHARE.svg',
    },
    {
      address: '0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
      chainId: 250,
      name: 'TREEB',
      symbol: 'TREEB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TREEB.svg',
    },
    {
      address: '0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
      chainId: 250,
      name: 'ZOO (OLD)',
      symbol: 'ZOO (OLD)',
      decimals: 0,
      logoURI: 'https://assets.lif3.com/swap/tokens/ZOO.svg',
    },
    {
      address: '0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
      chainId: 250,
      name: 'SCREAM',
      symbol: 'SCREAM',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SCREAM.svg',
    },
    {
      address: '0x511D35c52a3C244E7b8bd92c0C297755FbD89212',
      chainId: 250,
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/AVAX.svg',
    },
    {
      address: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
      chainId: 250,
      name: 'DAI',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    },
    {
      address: '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
      chainId: 250,
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BNB.svg',
    },
    {
      address: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
      chainId: 250,
      name: 'CRV',
      symbol: 'CRV',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/CRV.svg',
    },
    {
      address: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
      chainId: 250,
      name: 'LINK',
      symbol: 'LINK',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LINK.svg',
    },
    {
      address: '0xbf60e7414EF09026733c1E7de72E7393888C64DA',
      chainId: 250,
      name: 'LIF3 (OLD)',
      symbol: 'LIF3 (OLD)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0xCbE0CA46399Af916784cADF5bCC3aED2052D6C45',
      chainId: 250,
      name: 'LSHARE (OLD)',
      symbol: 'LSHARE (OLD)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x5f0456F728E2D59028b4f5B8Ad8C604100724C6A',
      chainId: 250,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
    {
      address: '0xcc3BED3712CbC88994f85c8a06405A6CE807ba1f',
      chainId: 250,
      name: 'QUINT',
      symbol: 'QUINT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/QUINT.svg',
    },
    {
      address: '0x5e074C28149cC35c1e5fbC79d8A6ea688dba2314',
      chainId: 250,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x8fF27b58E1d969bEFC9c8aEAE93A6EB7E2250c8f',
      chainId: 250,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x90152f061F6697aB623e258aA6e8FbB177041371',
      chainId: 250,
      name: 'ZOO',
      symbol: 'ZOO',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/ZOO.svg',
    },
  ] as TokensConfigItem[],
  [ChainId.BSC]: [
    {
      address: '0x5248f6c6196EB9DF6fa4870c66f97638F33D13B0',
      chainId: ChainId.BSC,
      name: 'TSHARE',
      symbol: 'TSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TSHARE.svg',
    },
    {
      address: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
      chainId: ChainId.BSC,
      name: 'LSHARE (OLD)',
      symbol: 'LSHARE (OLD)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
      chainId: ChainId.BSC,
      name: 'LIF3 (OLD)',
      symbol: 'LIF3 (OLD)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x0c08638473CAfbCa3beb113616A1871F4bfaD4f9',
      chainId: ChainId.BSC,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x336f374198c872Ba760e85af9c6331C3c5A535d3',
      chainId: ChainId.BSC,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      chainId: ChainId.BSC,
      name: 'Wrapped BNB',
      symbol: 'WBNB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WBNB.svg',
    },
    {
      address: '0x8f01d597d2022656494E30fb76eccf1eEA2E092e',
      chainId: ChainId.BSC,
      name: 'TOMB',
      symbol: 'TOMB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TOMB.svg',
    },
    {
      address: '0x55d398326f99059fF775485246999027B3197955',
      chainId: ChainId.BSC,
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      chainId: ChainId.BSC,
      name: 'Binance USD',
      symbol: 'BUSD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BUSD.svg',
    },
    {
      address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      chainId: ChainId.BSC,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      chainId: ChainId.BSC,
      name: 'Bitcoin',
      symbol: 'BTCB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BTC.svg',
    },
    {
      address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      chainId: ChainId.BSC,
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/ETH.svg',
    },
    {
      address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      chainId: ChainId.BSC,
      name: 'PancakeSwap Token',
      symbol: 'CAKE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/CAKE.svg',
    },
    {
      address: '0xAD6742A35fB341A9Cc6ad674738Dd8da98b94Fb1',
      chainId: ChainId.BSC,
      name: 'Wombat',
      symbol: 'WOM',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WOM.svg',
    },
    {
      address: '0x64619f611248256F7F4b72fE83872F89d5d60d64',
      chainId: ChainId.BSC,
      name: 'Quint',
      symbol: 'QUINT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/QUINT.svg',
    },
    {
      address: '0xDEa12C8C23994EA2D88eD99eE1bdc0FF56f7F9d1',
      chainId: ChainId.BSC,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
  ] as TokensConfigItem[],
  [ChainId.POLYGON]: [
    {
      address: '0xFB40b1eFe90D4b786D2D9d9dc799B18BDe92923b',
      chainId: ChainId.POLYGON,
      name: 'LSHARE (OLD)',
      symbol: 'LSHARE (OLD)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x5eAB32Fe1D104cE0C5436feDC3433B646096e47C',
      chainId: ChainId.POLYGON,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x0e98C977B943f06075b2D795794238fBfB9b9a34',
      chainId: ChainId.POLYGON,
      name: 'TOMB',
      symbol: 'TOMB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TOMB.svg',
    },
    {
      address: '0x2D1c6a7362E75d88B4Cf3b66b83052775846E2b8',
      chainId: ChainId.POLYGON,
      name: 'TSHARE',
      symbol: 'TSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TSHARE.svg',
    },
    {
      address: '0x2C2D8a078B33bf7782a16AcCE2C5BA6653a90D5f',
      chainId: ChainId.POLYGON,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
    {
      address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
      chainId: ChainId.POLYGON,
      name: 'Bitcoin',
      symbol: 'WBTC',
      decimals: 8,
      logoURI: 'https://assets.lif3.com/swap/tokens/WBTC.svg',
    },
    {
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      chainId: ChainId.POLYGON,
      name: 'Ethereum',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    },
    {
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      chainId: ChainId.POLYGON,
      name: 'Wrapped Matic',
      symbol: 'WMATIC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WMATIC.svg',
    },
    {
      address: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
      chainId: ChainId.POLYGON,
      name: 'QuickSwap',
      symbol: 'QUICK',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/QUICK.svg',
    },
    {
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      chainId: ChainId.POLYGON,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      chainId: ChainId.POLYGON,
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      chainId: ChainId.POLYGON,
      name: 'DAI',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    },
    {
      address: '0x56ac3Cb5E74b8A5A25ec9Dc05155aF1dad2715bd',
      chainId: ChainId.POLYGON,
      name: 'LIF3 (OLD)',
      symbol: 'LIF3 (OLD)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x110B25D2B21eE73eB401F3aE7833f7072912A0Bf',
      chainId: ChainId.POLYGON,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
  ] as TokensConfigItem[],
  [ChainId.AMOY_TESTNET]: [
    {
      address: '0x9880aDdD2b2acA4a8fa02274dAd1679B38F0A303',
      chainId: ChainId.AMOY_TESTNET,
      name: 'LSHARE (OLD)',
      symbol: 'LSHARE (OLD)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x9880aDdD2b2acA4a8fa02274dAd1679B38F0A303',
      chainId: ChainId.AMOY_TESTNET,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x0e98C977B943f06075b2D795794238fBfB9b9a34',
      chainId: ChainId.AMOY_TESTNET,
      name: 'TOMB',
      symbol: 'TOMB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TOMB.svg',
    },
    {
      address: '0x2D1c6a7362E75d88B4Cf3b66b83052775846E2b8',
      chainId: ChainId.AMOY_TESTNET,
      name: 'TSHARE',
      symbol: 'TSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TSHARE.svg',
    },
    {
      address: '0x2C2D8a078B33bf7782a16AcCE2C5BA6653a90D5f',
      chainId: ChainId.AMOY_TESTNET,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
    {
      address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
      chainId: ChainId.AMOY_TESTNET,
      name: 'Bitcoin',
      symbol: 'WBTC',
      decimals: 8,
      logoURI: 'https://assets.lif3.com/swap/tokens/WBTC.svg',
    },
    {
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      chainId: ChainId.AMOY_TESTNET,
      name: 'Ethereum',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    },
    {
      address: '0x40FE2F4EeaD3d472E5c064b8e970e5a595A44558',
      chainId: ChainId.AMOY_TESTNET,
      name: 'Wrapped Matic',
      symbol: 'WMATIC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WMATIC.svg',
    },
    {
      address: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
      chainId: ChainId.AMOY_TESTNET,
      name: 'QuickSwap',
      symbol: 'QUICK',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/QUICK.svg',
    },
    {
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      chainId: ChainId.AMOY_TESTNET,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      chainId: ChainId.AMOY_TESTNET,
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      chainId: ChainId.AMOY_TESTNET,
      name: 'DAI',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    },
    {
      address: '0x293475783df8fc51258b1004aFF1Cb1886BbDa6e',
      chainId: ChainId.AMOY_TESTNET,
      name: 'LIF3 (OLD)',
      symbol: 'LIF3 (OLD)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x293475783df8fc51258b1004aFF1Cb1886BbDa6e',
      chainId: ChainId.AMOY_TESTNET,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
  ] as TokensConfigItem[],
  [ChainId.ETHEREUM]: [
    {
      address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      chainId: ChainId.ETHEREUM,
      name: 'WETH',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    },
    {
      address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      chainId: ChainId.ETHEREUM,
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      chainId: ChainId.ETHEREUM,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
      chainId: ChainId.ETHEREUM,
      name: 'Binance USD',
      symbol: 'BUSD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BUSD.svg',
    },
    {
      address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
      chainId: ChainId.ETHEREUM,
      name: 'Matic Token',
      symbol: 'MATIC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/MATIC.svg',
    },
    {
      address: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
      chainId: ChainId.ETHEREUM,
      name: 'Lido Staked Ether',
      symbol: 'stETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/STETH.svg',
    },
    {
      address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
      chainId: ChainId.ETHEREUM,
      name: 'SHIBA INU',
      symbol: 'SHIB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SHIB.svg',
    },
    {
      address: '0x6b175474e89094c44da98b954eedeac495271d0f',
      chainId: ChainId.ETHEREUM,
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    },
    {
      address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
      chainId: ChainId.ETHEREUM,
      name: 'Uniswap',
      symbol: 'UNI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/UNI.svg',
    },
    {
      address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      chainId: ChainId.ETHEREUM,
      name: 'Wrapped BTC',
      symbol: 'WBTC',
      decimals: 8,
      logoURI: 'https://assets.lif3.com/swap/tokens/WBTC.svg',
    },
    {
      address: '0x514910771af9ca656af840dff83e8264ecf986ca',
      chainId: ChainId.ETHEREUM,
      name: 'ChainLink',
      symbol: 'LINK',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LINK.svg',
    },
    {
      address: '0x4a220e6096b25eadb88358cb44068a3248254675',
      chainId: ChainId.ETHEREUM,
      name: 'Quant',
      symbol: 'QNT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/QNT.svg',
    },
    {
      address: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
      chainId: ChainId.ETHEREUM,
      name: 'CRO',
      symbol: 'CRO',
      decimals: 8,
      logoURI: 'https://assets.lif3.com/swap/tokens/CRO.svg',
    },
    {
      address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
      chainId: ChainId.ETHEREUM,
      name: 'ApeCoin',
      symbol: 'APE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/APE.svg',
    },
    {
      address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
      chainId: ChainId.ETHEREUM,
      name: 'Lido DAO Token',
      symbol: 'LDO',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LDO.svg',
    },
    {
      address: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
      chainId: ChainId.ETHEREUM,
      name: 'Mana',
      symbol: 'MANA',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/MANA.svg',
    },
    {
      address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
      chainId: ChainId.ETHEREUM,
      name: 'Aave Token',
      symbol: 'AAVE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/AAVE.svg',
    },
    {
      address: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
      chainId: ChainId.ETHEREUM,
      name: 'SAND',
      symbol: 'SAND',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SAND.svg',
    },
    {
      address: '0x853d955acef822db058eb8505911ed77f175b99e',
      chainId: ChainId.ETHEREUM,
      name: 'Frax',
      symbol: 'FRAX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/FRAX.svg',
    },
    {
      address: '0x0000000000085d4780b73119b644ae5ecd22b376',
      chainId: ChainId.ETHEREUM,
      name: 'TrueUSD',
      symbol: 'TUSD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TUSD.svg',
    },
    {
      address: '0x8e870d67f660d95d5be530380d0ec0bd388289e1',
      chainId: ChainId.ETHEREUM,
      name: 'Pax Dollar',
      symbol: 'USDP',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDP.svg',
    },
    {
      address: '0x6f259637dcd74c767781e37bc6133cd6a68aa161',
      chainId: ChainId.ETHEREUM,
      name: 'HuobiToken',
      symbol: 'HT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/HT.svg',
    },
    {
      address: '0xd33526068d116ce69f19a9ee46f0bd304f21a51f',
      chainId: ChainId.ETHEREUM,
      name: 'Rocket Pool Protocol',
      symbol: 'RPL',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/RPL.svg',
    },
    {
      address: '0xc669928185dbce49d2230cc9b0979be6dc797957',
      chainId: ChainId.ETHEREUM,
      name: 'BitTorrent',
      symbol: 'BTT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BTT.svg',
    },
    {
      address: '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
      chainId: ChainId.ETHEREUM,
      name: 'Frax Share',
      symbol: 'FXS',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/FXS.svg',
    },
    {
      address: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
      chainId: ChainId.ETHEREUM,
      name: 'Maker',
      symbol: 'MKR',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/MKR.svg',
    },
    {
      address: '0xd533a949740bb3306d119cc777fa900ba034cd52',
      chainId: ChainId.ETHEREUM,
      name: 'Curve DAO Token',
      symbol: 'CRV',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/CRV.svg',
    },
    {
      address: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
      chainId: ChainId.ETHEREUM,
      name: 'Syntethix Network Token',
      symbol: 'SNX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SNX.svg',
    },
    {
      address: '0x111111111117dc0aa78b770fa6a738034120c302',
      chainId: ChainId.ETHEREUM,
      name: '1INCH Token',
      symbol: '1INCH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/1INCH.svg',
    },
    {
      address: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
      chainId: ChainId.ETHEREUM,
      name: 'Convex Token',
      symbol: 'CVX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/CVX.svg',
    },
    {
      address: '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72',
      chainId: ChainId.ETHEREUM,
      name: 'Ethereum Name Service',
      symbol: 'ENS',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/ENS.svg',
    },
    {
      address: '0xae78736cd615f374d3085123a210448e74fc6393',
      chainId: ChainId.ETHEREUM,
      name: 'Rocket Pool ETH',
      symbol: 'RETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/RETH.svg',
    },
    {
      address: '0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5',
      chainId: ChainId.ETHEREUM,
      name: 'Olympus',
      symbol: 'OHM',
      decimals: 9,
      logoURI: 'https://assets.lif3.com/swap/tokens/OHM.svg',
    },
    {
      address: '0xba100000625a3754423978a60c9317c58a424e3d',
      chainId: ChainId.ETHEREUM,
      name: 'Balancer',
      symbol: 'BAL',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BAL.svg',
    },
    {
      address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
      chainId: ChainId.ETHEREUM,
      name: 'SushiToken',
      symbol: 'SUSHI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SUSHI.svg',
    },
    {
      address: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
      chainId: ChainId.ETHEREUM,
      name: 'yearn.finance',
      symbol: 'YFI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/YFI.svg',
    },
    {
      address: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55',
      chainId: ChainId.ETHEREUM,
      name: 'Band Protocol',
      symbol: 'BAND',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BAND.svg',
    },
    {
      address: '0x92d6c1e31e14520e676a687f0a93788b716beff5',
      chainId: ChainId.ETHEREUM,
      name: 'dYdX',
      symbol: 'dYdX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/DYDX.svg',
    },
    {
      address: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
      chainId: ChainId.ETHEREUM,
      name: 'Ankr Network',
      symbol: 'ANKR',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/ANKR.svg',
    },
    {
      address: '0xac57de9c1a09fec648e93eb98875b212db0d460b',
      chainId: ChainId.ETHEREUM,
      name: 'Baby Doge Coin',
      symbol: 'BabyDoge',
      decimals: 9,
      logoURI: 'https://assets.lif3.com/swap/tokens/BABYDOGE.svg',
    },
    {
      address: '0x0f2d719407fdbeff09d87557abb7232601fd9f29',
      chainId: ChainId.ETHEREUM,
      name: 'Synapse',
      symbol: 'SYN',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SYN.svg',
    },
    {
      address: '0x476c5e26a75bd202a9683ffd34359c0cc15be0ff',
      chainId: ChainId.ETHEREUM,
      name: 'Serum',
      symbol: 'SRM',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/SRM.svg',
    },
    {
      address: '0xe28b3b32b6c345a34ff64674606124dd5aceca30',
      chainId: ChainId.ETHEREUM,
      name: 'Injective Token',
      symbol: 'INJ',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/INJ.svg',
    },
    {
      address: '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3',
      chainId: ChainId.ETHEREUM,
      name: 'Magic Internet Money',
      symbol: 'MIM',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/MIM.svg',
    },
    {
      address: '0x090185f2135308bad17527004364ebcc2d37e5f6',
      chainId: ChainId.ETHEREUM,
      name: 'Spell Token',
      symbol: 'SPELL',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SPELL.svg',
    },
    {
      address: '0xf16e81dce15b08f326220742020379b855b87df9',
      chainId: ChainId.ETHEREUM,
      name: 'ICE',
      symbol: 'ICE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/ICE.svg',
    },
    {
      address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
      chainId: ChainId.ETHEREUM,
      name: 'HEX',
      symbol: 'HEX',
      decimals: 8,
      logoURI: 'https://assets.lif3.com/swap/tokens/HEX.svg',
    },
    {
      address: '0x6f9c26fa731c7ea4139fa669962cf8f1ce6c8b0b',
      chainId: ChainId.ETHEREUM,
      name: 'OATH',
      symbol: 'OATH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/OATH.svg',
    },
    {
      address: '0xf88baf18fab7e330fa0c4f83949e23f52fececce',
      chainId: ChainId.ETHEREUM,
      name: 'GRAIN',
      symbol: 'GRAIN',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/GRAIN.svg',
    },
    {
      address: '0x6982508145454ce325ddbe47a25d4ec3d2311933',
      chainId: ChainId.ETHEREUM,
      name: 'PEPE',
      symbol: 'PEPE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/PEPE.svg',
    },
    {
      address: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
      chainId: ChainId.ETHEREUM,
      name: 'Axie Infinity Shard',
      symbol: 'AXS',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/AXS.svg',
    },
    {
      address: '0x3506424f91fd33084466f402d5d97f05f8e3b4af',
      chainId: ChainId.ETHEREUM,
      name: 'chiliZ',
      symbol: 'CHZ',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/CHZ.svg',
    },
    {
      address: '0xcf0c122c6b73ff809c693db761e7baebe62b6a2e',
      chainId: ChainId.ETHEREUM,
      name: 'FLOKI',
      symbol: 'FLOKI',
      decimals: 9,
      logoURI: 'https://assets.lif3.com/swap/tokens/FLOKI.svg',
    },
    {
      address: '0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d',
      chainId: ChainId.ETHEREUM,
      name: 'LQTY',
      symbol: 'LQTY',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LQTY.svg',
    },
    {
      address: '0xb50721bcf8d664c30412cfbc6cf7a15145234ad1',
      chainId: ChainId.ETHEREUM,
      name: 'Arbitrum',
      symbol: 'ARB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/ARB.svg',
    },
    {
      address: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
      chainId: ChainId.ETHEREUM,
      name: 'Fantom Token',
      symbol: 'FTM',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/FTM.svg',
    },
    {
      address: '0xd1d2eb1b1e90b638588728b4130137d262c87cae',
      chainId: ChainId.ETHEREUM,
      name: 'GALA',
      symbol: 'GALA',
      decimals: 8,
      logoURI: 'https://assets.lif3.com/swap/tokens/GALA.svg',
    },
    {
      address: '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24',
      chainId: ChainId.ETHEREUM,
      name: 'Render ',
      symbol: 'RNDR',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/RNDR.svg',
    },
    {
      address: '0xc944e90c64b2c07662a292be6244bdf05cda44a7',
      chainId: ChainId.ETHEREUM,
      name: 'The Graph',
      symbol: 'GRT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/GRT.svg',
    },
    {
      address: '0x5283d291dbcf85356a21ba090e6db59121208b44',
      chainId: ChainId.ETHEREUM,
      name: 'Blur ',
      symbol: 'BLUR',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BLUR.svg',
    },
    {
      address: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
      chainId: ChainId.ETHEREUM,
      name: 'WOO Network',
      symbol: 'WOO',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WOO.svg',
    },
    {
      address: '0x7138eb0d563f3f6722500936a11dcae99d738a2c',
      chainId: ChainId.ETHEREUM,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x8e01397163b21f64cec1f06ca6cc7d9aa8f718e9',
      chainId: ChainId.ETHEREUM,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
  ] as TokensConfigItem[],
  [ChainId.OPTIMISM]: [
    {
      address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
      chainId: ChainId.OPTIMISM,
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
      chainId: ChainId.OPTIMISM,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6',
      chainId: ChainId.OPTIMISM,
      name: 'Chainlink',
      symbol: 'LINK',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LINK.svg',
    },
    {
      address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      chainId: ChainId.OPTIMISM,
      name: 'Dai',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    },
    {
      address: '0x4200000000000000000000000000000000000042',
      chainId: ChainId.OPTIMISM,
      name: 'Optimism',
      symbol: 'OP',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/OP.svg',
    },
    {
      address: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
      chainId: ChainId.OPTIMISM,
      name: 'Wrapped BTC',
      symbol: 'WBTC',
      decimals: 8,
      logoURI: 'https://assets.lif3.com/swap/tokens/WBTC.svg',
    },
    {
      address: '0xFdb794692724153d1488CcdBE0C56c252596735F',
      chainId: ChainId.OPTIMISM,
      name: 'Lido DAO',
      symbol: 'LDO',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LDO.svg',
    },
    {
      address: '0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4',
      chainId: ChainId.OPTIMISM,
      name: 'Synthetix Network',
      symbol: 'SNX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SNX.svg',
    },
    {
      address: '0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be',
      chainId: ChainId.OPTIMISM,
      name: 'Frax Share',
      symbol: 'FXS',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/FXS.svg',
    },
    {
      address: '0xa00E3A3511aAC35cA78530c85007AFCd31753819',
      chainId: ChainId.OPTIMISM,
      name: 'Kyber Network Crystal',
      symbol: 'KNC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/KNC.svg',
    },
    {
      address: '0xFE8B128bA8C78aabC59d4c64cEE7fF28e9379921',
      chainId: ChainId.OPTIMISM,
      name: 'Balancer',
      symbol: 'BAL',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BAL.svg',
    },
    {
      address: '0x6806411765Af15Bddd26f8f544A34cC40cb9838B',
      chainId: ChainId.OPTIMISM,
      name: 'Frax Ether',
      symbol: 'frxETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/frxETH.svg',
    },
    {
      address: '0x2E3D870790dC77A83DD1d18184Acc7439A53f475',
      chainId: ChainId.OPTIMISM,
      name: 'Frax',
      symbol: 'FRAX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/FRAX.svg',
    },
    {
      address: '0x3c8B650257cFb5f272f799F5e2b4e65093a11a05',
      chainId: ChainId.OPTIMISM,
      name: 'Velodrome',
      symbol: 'VELO',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/VELO.svg',
    },
    {
      address: '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
      chainId: ChainId.OPTIMISM,
      name: 'Synth sUSD',
      symbol: 'sUSD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/sUSD.svg',
    },
    {
      address: '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
      chainId: ChainId.OPTIMISM,
      name: 'BOB',
      symbol: 'BOB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BOB.svg',
    },
    {
      address: '0xE405de8F52ba7559f9df3C368500B6E6ae6Cee49',
      chainId: ChainId.OPTIMISM,
      name: 'Synth sETH',
      symbol: 'sETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/sETH.svg',
    },
    {
      address: '0x73cb180bf0521828d8849bc8CF2B920918e23032',
      chainId: ChainId.OPTIMISM,
      name: 'USD+',
      symbol: 'USD',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USD+.svg',
    },
    {
      address: '0xb12c13e66AdE1F72f71834f2FC5082Db8C091358',
      chainId: ChainId.OPTIMISM,
      name: 'ROOBEE',
      symbol: 'ROOBEE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/ROOBEE.svg',
    },
    {
      address: '0xc5102fE9359FD9a28f877a67E36B0F050d81a3CC',
      chainId: ChainId.OPTIMISM,
      name: 'Hop',
      symbol: 'HOP',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/HOP.svg',
    },
    {
      address: '0x484c2D6e3cDd945a8B2DF735e079178C1036578c',
      chainId: ChainId.OPTIMISM,
      name: 'Staked Frax Ether',
      symbol: 'sfrxETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/sfrxETH.svg',
    },
    {
      address: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
      chainId: ChainId.OPTIMISM,
      name: 'Lyra Token',
      symbol: 'LYRA',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LYRA.svg',
    },
    {
      address: '0x217D47011b23BB961eB6D93cA9945B7501a5BB11',
      chainId: ChainId.OPTIMISM,
      name: 'Thales DAO Token',
      symbol: 'THALES',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/THALES.svg',
    },
    {
      address: '0x0c5b4c92c948691EEBf185C17eeB9c230DC019E9',
      chainId: ChainId.OPTIMISM,
      name: 'PickleToken',
      symbol: 'PICKLE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/PICKLE.svg',
    },
    {
      address: '0x4E720DD3Ac5CFe1e1fbDE4935f386Bb1C66F4642',
      chainId: ChainId.OPTIMISM,
      name: 'Beefy',
      symbol: 'BIFI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BIFI.svg',
    },
    {
      address: '0x117cFd9060525452db4A34d51c0b3b7599087f05',
      chainId: ChainId.OPTIMISM,
      name: 'Geyser',
      symbol: 'GYSR',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/GYSR.svg',
    },
    {
      address: '0x298B9B95708152ff6968aafd889c6586e9169f1D',
      chainId: ChainId.OPTIMISM,
      name: 'Synth sBTC',
      symbol: 'sBTC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/sBTC.svg',
    },
    {
      address: '0xf899e3909B4492859d44260E1de41A9E663e70F5',
      chainId: ChainId.OPTIMISM,
      name: 'RadioShack Token',
      symbol: 'RADIO',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/RADIO.svg',
    },
    {
      address: '0x296F55F8Fb28E498B858d0BcDA06D955B2Cb3f97',
      chainId: ChainId.OPTIMISM,
      name: 'StargateToken',
      symbol: 'STG',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/STG.svg',
    },
    {
      address: '0x920Cf626a271321C151D027030D5d08aF699456b',
      chainId: ChainId.OPTIMISM,
      name: 'Kwenta',
      symbol: 'KWENTA',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/KWENTA.svg',
    },
    {
      address: '0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39',
      chainId: ChainId.OPTIMISM,
      name: 'BUSD',
      symbol: 'BUSD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BUSD.svg',
    },
    {
      address: '0xcdB4bB51801A1F399d4402c61bC098a72c382E65',
      chainId: ChainId.OPTIMISM,
      name: 'OPX',
      symbol: 'OPX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/OPX.svg',
    },
    {
      address: '0x4200000000000000000000000000000000000006',
      chainId: ChainId.OPTIMISM,
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    },
    {
      address: '0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb',
      chainId: ChainId.OPTIMISM,
      name: 'Wrapped liquid staked Ether 2.0',
      symbol: 'wstETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/wstETH.svg',
    },
    {
      address: '0x9Bcef72be871e61ED4fBbc7630889beE758eb81D',
      chainId: ChainId.OPTIMISM,
      name: 'Rocket Pool ETH',
      symbol: 'rETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/rETH.svg',
    },
  ] as TokensConfigItem[],
  [ChainId.ARBITRUM]: [
    {
      address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      chainId: ChainId.ARBITRUM,
      name: 'Wrapped Ethereum',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    },
    {
      address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      chainId: ChainId.ARBITRUM,
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      chainId: ChainId.ARBITRUM,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      chainId: ChainId.ARBITRUM,
      name: 'Dai',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    },
    {
      address: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
      chainId: ChainId.ARBITRUM,
      name: 'Wrapped Bitcoin',
      symbol: 'WBTC',
      decimals: 8,
      logoURI: 'https://assets.lif3.com/swap/tokens/WBTC.svg',
    },
    {
      address: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
      chainId: ChainId.ARBITRUM,
      name: 'Chainlink',
      symbol: 'LINK',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LINK.svg',
    },
    {
      address: '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
      chainId: ChainId.ARBITRUM,
      name: 'Uniswap',
      symbol: 'UNI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/UNI.svg',
    },
    {
      address: '0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07',
      chainId: ChainId.ARBITRUM,
      name: 'TrueUSD',
      symbol: 'TUSD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TUSD.svg',
    },
    {
      address: '0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60',
      chainId: ChainId.ARBITRUM,
      name: 'Lido DAO',
      symbol: 'LDO',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LDO.svg',
    },
    {
      address: '0x912CE59144191C1204E64559FE8253a0e49E6548',
      chainId: ChainId.ARBITRUM,
      name: 'Arbitrum',
      symbol: 'ARB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/ARB.svg',
    },
    {
      address: '0x9623063377AD1B27544C965cCd7342f7EA7e88C7',
      chainId: ChainId.ARBITRUM,
      name: 'The Graph',
      symbol: 'GRT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/GRT.svg',
    },
    {
      address: '0x680447595e8b7b3Aa1B43beB9f6098C79ac2Ab3f',
      chainId: ChainId.ARBITRUM,
      name: 'USDD',
      symbol: 'USDD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDD.svg',
    },
    {
      address: '0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978',
      chainId: ChainId.ARBITRUM,
      name: 'Curve DAO',
      symbol: 'CRV',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/CRV.svg',
    },
    {
      address: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
      chainId: ChainId.ARBITRUM,
      name: 'GMX',
      symbol: 'GMX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/GMX.svg',
    },
    {
      address: '0x09E18590E8f76b6Cf471b3cd75fE1A1a9D2B2c2b',
      chainId: ChainId.ARBITRUM,
      name: 'ArbDoge AI',
      symbol: 'AIDOGE',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/AIDOGE.svg',
    },
    {
      address: '0x539bdE0d7Dbd336b79148AA742883198BBF60342',
      chainId: ChainId.ARBITRUM,
      name: 'MAGIC',
      symbol: 'MAGIC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/MAGIC.svg',
    },
    {
      address: '0x319f865b287fCC10b30d8cE6144e8b6D1b476999',
      chainId: ChainId.ARBITRUM,
      name: 'Cartesi',
      symbol: 'CTSI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/CTSI.svg',
    },
    {
      address: '0x753D224bCf9AAFaCD81558c32341416df61D3DAC',
      chainId: ChainId.ARBITRUM,
      name: 'Perpetual Protocol',
      symbol: 'PERP',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/PERP.svg',
    },
    {
      address: '0xcAFcD85D8ca7Ad1e1C6F82F651fA15E33AEfD07b',
      chainId: ChainId.ARBITRUM,
      name: 'WOO Network',
      symbol: 'WOO',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WOO.svg',
    },
    {
      address: '0x3082CC23568eA640225c2467653dB90e9250AaA0',
      chainId: ChainId.ARBITRUM,
      name: 'Radiant Capital',
      symbol: 'RDNT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/RDNT.svg',
    },
    {
      address: '0x82e3A8F066a6989666b031d916c43672085b1582',
      chainId: ChainId.ARBITRUM,
      name: 'yearn.finance',
      symbol: 'YFI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/YFI.svg',
    },
    {
      address: '0x46d0cE7de6247b0A95f67b43B589b4041BaE7fbE',
      chainId: ChainId.ARBITRUM,
      name: 'Loopring',
      symbol: 'LRC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LRC.svg',
    },
    {
      address: '0x9d2F299715D94d8A7E6F5eaa8E654E8c74a988A7',
      chainId: ChainId.ARBITRUM,
      name: 'Frax Share',
      symbol: 'FXS',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/FXS.svg',
    },
    {
      address: '0x354A6dA3fcde098F8389cad84b0182725c6C91dE',
      chainId: ChainId.ARBITRUM,
      name: 'Compound',
      symbol: 'COMP',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/COMP.svg',
    },
    {
      address: '0x3a8B787f78D775AECFEEa15706D4221B40F345AB',
      chainId: ChainId.ARBITRUM,
      name: 'Celer Network',
      symbol: 'CELR',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/CELR.svg',
    },
    {
      address: '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07',
      chainId: ChainId.ARBITRUM,
      name: 'Trader Joe',
      symbol: 'JOE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/JOE.svg',
    },
    {
      address: '0x6694340fc020c5E6B96567843da2df01b2CE1eb6',
      chainId: ChainId.ARBITRUM,
      name: 'Stargate Finance',
      symbol: 'STG',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/STG.svg',
    },
    {
      address: '0x18c11FD286C5EC11c3b683Caa813B77f5163A122',
      chainId: ChainId.ARBITRUM,
      name: 'Gains Network',
      symbol: 'GNS',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/GNS.svg',
    },
    {
      address: '0x0D81E50bC677fa67341c44D7eaA9228DEE64A4e1',
      chainId: ChainId.ARBITRUM,
      name: 'BarnBridge',
      symbol: 'BOND',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BOND.svg',
    },
    {
      address: '0x080F6AEd32Fc474DD5717105Dba5ea57268F46eb',
      chainId: ChainId.ARBITRUM,
      name: 'Synapse',
      symbol: 'SYN',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SYN.svg',
    },
    {
      address: '0x289ba1701C2F088cf0faf8B3705246331cB8A839',
      chainId: ChainId.ARBITRUM,
      name: 'Livepeer',
      symbol: 'LPT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LPT.svg',
    },
    {
      address: '0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8',
      chainId: ChainId.ARBITRUM,
      name: 'Balancer',
      symbol: 'BAL',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BAL.svg',
    },
    {
      address: '0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF',
      chainId: ChainId.ARBITRUM,
      name: 'Spell Token',
      symbol: 'SPELL',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SPELL.svg',
    },
    {
      address: '0x69Eb4FA4a2fbd498C257C57Ea8b7655a2559A581',
      chainId: ChainId.ARBITRUM,
      name: 'DODO',
      symbol: 'DODO',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/DODO.svg',
    },
    {
      address: '0x088cd8f5eF3652623c22D48b1605DCfE860Cd704',
      chainId: ChainId.ARBITRUM,
      name: 'Vela Exchange',
      symbol: 'VELA',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/VELA.svg',
    },
    {
      address: '0xdA0a57B710768ae17941a9Fa33f8B720c8bD9ddD',
      chainId: ChainId.ARBITRUM,
      name: 'Marlin',
      symbol: 'POND',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/POND.svg',
    },
    {
      address: '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
      chainId: ChainId.ARBITRUM,
      name: 'Frax',
      symbol: 'FRAX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/FRAX.svg',
    },
    {
      address: '0xaE6aab43C4f3E0cea4Ab83752C278f8dEbabA689',
      chainId: ChainId.ARBITRUM,
      name: 'dForce',
      symbol: 'DF',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/DF.svg',
    },
    {
      address: '0xe4DDDfe67E7164b0FE14E218d80dC4C08eDC01cB',
      chainId: ChainId.ARBITRUM,
      name: 'Kyber Network Crystal',
      symbol: 'KNC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/KNC.svg',
    },
    {
      address: '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
      chainId: ChainId.ARBITRUM,
      name: 'Multichain',
      symbol: 'MULTI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/MULTI.svg',
    },
    {
      address: '0x3d9907F9a368ad0a51Be60f7Da3b97cf940982D8',
      chainId: ChainId.ARBITRUM,
      name: 'Camelot Token',
      symbol: 'GRAIL',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/GRAIL.svg',
    },
    {
      address: '0xBfa641051Ba0a0Ad1b0AcF549a89536A0D76472E',
      chainId: ChainId.ARBITRUM,
      name: 'Badger DAO',
      symbol: 'BADGER',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BADGER.svg',
    },
    {
      address: '0xa0b862F60edEf4452F25B4160F177db44DeB6Cf1',
      chainId: ChainId.ARBITRUM,
      name: 'Gnosis',
      symbol: 'GNO',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/GNO.svg',
    },
    {
      address: '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
      chainId: ChainId.ARBITRUM,
      name: 'MAI',
      symbol: 'MIMATIC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/MIMATIC.svg',
    },
    {
      address: '0x51318B7D00db7ACc4026C88c3952B66278B6A67F',
      chainId: ChainId.ARBITRUM,
      name: 'PlutusDAO',
      symbol: 'PLS',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/PLS.svg',
    },
    {
      address: '0x99C409E5f62E4bd2AC142f17caFb6810B8F0BAAE',
      chainId: ChainId.ARBITRUM,
      name: 'Beefy Finance',
      symbol: 'BIFI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/BIFI.svg',
    },
    {
      address: '0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55',
      chainId: ChainId.ARBITRUM,
      name: 'Dopex',
      symbol: 'DPX',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/DPX.svg',
    },
    {
      address: '0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42',
      chainId: ChainId.ARBITRUM,
      name: 'MUX Protocol',
      symbol: 'MCB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/MCB.svg',
    },
    {
      address: '0x3B475F6f2f41853706afc9Fa6a6b8C5dF1a2724c',
      chainId: ChainId.ARBITRUM,
      name: 'Zyberswap',
      symbol: 'ZYB',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/ZYB.svg',
    },
    {
      address: '0xd4d42F0b6DEF4CE0383636770eF773390d85c61A',
      chainId: ChainId.ARBITRUM,
      name: 'SushiSwap',
      symbol: 'SUSHI',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SUSHI.svg',
    },
    {
      address: '0x431402e8b9dE9aa016C743880e04E517074D8cEC',
      chainId: ChainId.ARBITRUM,
      name: 'Hegic',
      symbol: 'HEGIC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/HEGIC.svg',
    },
    {
      address: '0x80bB30D62a16e1F2084dEAE84dc293531c3AC3A1',
      chainId: ChainId.ARBITRUM,
      name: 'Granary Finance',
      symbol: 'GRAIN',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/GRAIN.svg',
    },
    {
      address: '0x9E758B8a98a42d612b3D38B66a22074DC03D7370',
      chainId: ChainId.ARBITRUM,
      name: 'Symbiosis',
      symbol: 'SIS',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/SIS.svg',
    },
    {
      address: '0x6688b00f0c23a4a546BEaAE51a7c90C439895D48',
      chainId: ChainId.ARBITRUM,
      name: 'Tarot',
      symbol: 'TAROT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/TAROT.svg',
    },
    {
      address: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      chainId: ChainId.ARBITRUM,
      name: 'Chronos',
      symbol: 'CHR',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/CHR.svg',
    },
    {
      address: '0x7B5EB3940021Ec0e8e463D5dBB4B7B09a89DDF96',
      chainId: ChainId.ARBITRUM,
      name: 'Wombat Exchange',
      symbol: 'WOM',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WOM.svg',
    },
    {
      address: '0xAAA6C1E32C55A7Bfa8066A6FAE9b42650F262418',
      chainId: ChainId.ARBITRUM,
      name: 'Ramses Exchange',
      symbol: 'RAM',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/RAM.svg',
    },
    {
      address: '0x5979D7b546E38E414F7E9822514be443A4800529',
      chainId: ChainId.ARBITRUM,
      name: 'Wrapped liquid staked Ether 2.0',
      symbol: 'wstETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/wstETH.svg',
    },
  ] as TokensConfigItem[],
  [ChainId.BASE]: [
    {
      address: '0x4200000000000000000000000000000000000006',
      chainId: ChainId.BASE,
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
      chainId: ChainId.BASE,
      name: 'USD Base Coin',
      symbol: 'USDbC',
      decimals: 6,
      logoURI: '',
    },
    {
      address: '0x78a087d713Be963Bf307b18F2Ff8122EF9A63ae9',
      chainId: ChainId.BASE,
      name: 'Baseswap Token',
      symbol: 'BSWAP',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
      chainId: ChainId.BASE,
      name: 'Axelar Wrapped USDC',
      symbol: 'axlUSDC',
      decimals: 6,
      logoURI: '',
    },
    {
      address: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
      chainId: ChainId.BASE,
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0xE3B53AF74a4BF62Ae5511055290838050bf764Df',
      chainId: ChainId.BASE,
      name: 'StargateToken',
      symbol: 'STG',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0x22DC834C3Ff3e45f484bF24B9B07b851B981900f',
      chainId: ChainId.BASE,
      name: 'Smudge Cat',
      symbol: 'SMUDCAT',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0x8544FE9D190fD7EC52860abBf45088E81Ee24a8c',
      chainId: ChainId.BASE,
      name: 'Toshi',
      symbol: 'TOSHI',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0x27D2DECb4bFC9C76F0309b8E88dec3a601Fe25a8',
      chainId: ChainId.BASE,
      name: 'Bald',
      symbol: 'BALD',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0xe1f9ac62a2f34881f6Fe0F84322dE9d7024C2b8E',
      chainId: ChainId.BASE,
      name: 'Mochi',
      symbol: 'MOCHI',
      decimals: 8,
      logoURI: '',
    },
    {
      address: '0x6653dD4B92a0e5Bf8ae570A98906d9D6fD2eEc09',
      chainId: ChainId.BASE,
      name: 'RocketSwap',
      symbol: 'RCKT',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0x0963a1aBAF36Ca88C21032b82e479353126A1C4b',
      chainId: ChainId.BASE,
      name: 'leetswap.finance',
      symbol: 'LEET',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0xB3CE4Ce692e035720B25880f678871CfC5a863CA',
      chainId: ChainId.BASE,
      name: 'BaseTools',
      symbol: 'BASE',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0x4A3A6Dd60A34bB2Aba60D73B4C88315E9CeB6A3D',
      chainId: ChainId.BASE,
      name: 'Magic Internet Money',
      symbol: 'MIM',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22',
      chainId: ChainId.BASE,
      name: 'Coinbase Wrapped Staked ETH',
      symbol: 'cbETH',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0x7f5373AE26c3E8FfC4c77b7255DF7eC1A9aF52a6',
      chainId: ChainId.BASE,
      name: 'Axelar Wrapped USDT',
      symbol: 'axlUSDT',
      decimals: 6,
      logoURI: '',
    },
    {
      address: '0x01CC6b33c63CeE896521D63451896C14D42D05Ea',
      chainId: ChainId.BASE,
      name: 'Synth escrowed token',
      symbol: 'xSYNTH',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0xbd2DBb8eceA9743CA5B16423b4eAa26bDcfE5eD2',
      chainId: ChainId.BASE,
      name: 'Synth Token',
      symbol: 'SYNTH',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0x7c6b91D9Be155A6Db01f749217d76fF02A7227F2',
      chainId: ChainId.BASE,
      name: 'Balancer',
      symbol: 'BAL',
      decimals: 18,
      logoURI: '',
    },
    {
      address: '0x23E1A3BcDcEE4C59209d8871140eB7DD2bD9d1cE',
      chainId: ChainId.BASE,
      name: 'BASO',
      symbol: 'BASO',
      decimals: 18,
      logoURI: '',
    },
  ] as TokensConfigItem[],
  [ChainId.LIF3CHAIN]: [
    {
      address: '0xDEa12C8C23994EA2D88eD99eE1bdc0FF56f7F9d1',
      chainId: ChainId.LIF3CHAIN,
      name: 'Wrapped Lif3',
      symbol: 'WLIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    // {
    //   address: '0x2C2D8a078B33bf7782a16AcCE2C5BA6653a90D5f',
    //   chainId: ChainId.LIF3CHAIN,
    //   name: 'L3USD',
    //   symbol: 'L3USD',
    //   decimals: 18,
    //   logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    // },
  ],
  [ChainId.LIF3CHAIN_TESTNET]: [
    {
      address: '0x2C2D8a078B33bf7782a16AcCE2C5BA6653a90D5f',
      chainId: ChainId.LIF3CHAIN_TESTNET,
      name: 'Wrapped Lif3',
      symbol: 'WLIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    // {
    //   address: '0x621A936084BBb73F947C827a92C93Ef6A3612c3A',
    //   chainId: ChainId.LIF3CHAIN_TESTNET,
    //   name: 'USDT',
    //   symbol: 'USDT',
    //   decimals: 6,
    //   logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    // },
    // {
    //   address: '0x6aAaa67227D8D29D26115a433130D882A876507d',
    //   chainId: ChainId.LIF3CHAIN_TESTNET,
    //   name: 'LSHARE',
    //   symbol: 'LSHARE',
    //   decimals: 18,
    //   logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    // },
    {
      address: '0xfF0Eb1f5551E1EA2dee88AB443aFBE6EBdC4293f',
      chainId: ChainId.LIF3CHAIN_TESTNET,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
  ] as TokensConfigItem[],
}
